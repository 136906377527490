import Exec from '@libs/exec'
import {atPath, relativePath} from '@libs/utils'
import {fetchGetByIdQuery} from '@queries'
import {APIProxyV1Service, ErpApiService} from '@services'
import {Snippet} from '@shared/interfaces'
import {QueryClient} from '@tanstack/react-query'
import handlebars from '@libs/handlebars'

export const filterData = async (
  values: object,
  name: string,
  api: APIProxyV1Service,
  path?: string,
  context?: string
  /* Formikery */
) => {
  let data = path ? atPath(values, path) : values
  if (context) {
    const getValue = (value: string) => {
      return atPath(values, `${relativePath(name) ? `${relativePath(name)}.` : ''}${value}`)
    }
    try {
      const ret = await Exec(context, {
        values: data,
        api,
        helper: {getValue},
        name
      })
      if (ret) data = ret
    } catch (e) {
      console.warn('Widget Filter Function error', e)
    }
  }

  return data
}

export const filterTextData = async (
  erpApi: ErpApiService,
  queryClient: QueryClient,
  values: object,
  name: string,
  api: APIProxyV1Service,
  path?: string,
  snippet?: string,
  content?: string,
  context?: string
) => {
  const data = await filterData(values, name, api, path, context)
  if (snippet) {
    const snippetContent = await fetchGetByIdQuery<Snippet>(
      'snippets',
      snippet,
      erpApi,
      queryClient,
      {
        query: {staleTime: Infinity, gcTime: Infinity},
        api: {hydrate: true}
      }
    )
    if (snippetContent && snippetContent.code)
      return handlebars(snippetContent.code, data, {noEscape: true})
  }
  if (content) return handlebars(content, data, {noEscape: true})
  if (typeof data === 'string') return data
  return ''
}
