import React, {useCallback, useMemo} from 'react'
import {Button, Col, List, Space, Typography, UploadFile} from 'antd'
import {AppContext, withApp} from '@store/app'
import CloudinaryDragger from '@components/cloudinary-upload'
import {useCreateMutation, useGetQuery, useRemoveMutation} from '@queries'
import {ErpDomain, Upload} from '@shared/interfaces'
import {DeleteOutlined, EditOutlined} from '@ant-design/icons'
import {PanelsKeys} from '@libs/panels/panels.t'
import {useGetPaymentsQuery} from '@queries/projects'

const {Title, Text} = Typography

export interface UploadDocumentsProps extends AppContext {
  projectId: string
}

const UploadDocuments: React.FC<UploadDocumentsProps> = ({projectId, t, toast, CRM, Panels}) => {
  const createMutation = useCreateMutation()
  const removeItemMutation = useRemoveMutation()

  const domain: ErpDomain = useMemo(() => CRM?.getDomain('upload') as ErpDomain, [CRM])

  const paymentsData = useGetPaymentsQuery(projectId, true)

  const uploadsData = useGetQuery<Upload>('uploads', {
    api: {q: {project: projectId}, hydrate: true}
  })

  const openEditPanel = useCallback(
    (item: Upload) => {
      Panels.show(PanelsKeys.FORM_EDIT, {
        domain: 'upload',
        data: item,
        successMessage: 'Fichier modifié',
        onSave: async () => {
          uploadsData.refetch()
          window.SM?.sub.refresh('upload')
        }
      })
    },
    [Panels, uploadsData]
  )

  const removeItem = useCallback(
    async (item: Upload) => {
      return removeItemMutation
        .mutateAsync(
          {domain, id: item._id},
          {
            onSuccess: async () => {
              uploadsData.refetch()
              window.SM?.sub.refresh('upload')
              toast.success('action-bar.left-menu.removeItemSuccess')
            }
          }
        )
        .catch((err) => {
          const errorMessage =
            err.response?.data?.data?.err || 'action-bar.left-menu.removeItemError'
          toast.error(errorMessage)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [removeItemMutation, domain, uploadsData, toast]
  )

  const handleCreateAfterCloudinaryUpload = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (cloudinaryResponse: any, file: UploadFile) => {
      if (!domain) {
        throw new Error('Domain not found')
      }
      const payload = {
        name: file.name,
        file: {
          path: cloudinaryResponse.public_id,
          cat: cloudinaryResponse.created_at,
          type: file.type
        },
        project: projectId
      }
      await createMutation.mutateAsync(
        {domain, item: payload},
        {
          onSuccess: () => {
            uploadsData.refetch
            window.SM?.sub.refresh('upload')
          }
        }
      )
      toast.success('action-bar.create.domain.success')
    },
    [createMutation, domain, projectId, toast, uploadsData.refetch]
  )

  const uploads = useMemo(() => (!uploadsData.isSuccess ? [] : uploadsData.data), [uploadsData])

  const uncappedPayments = useMemo(() => {
    console.log(paymentsData.data)
    return (
      paymentsData.isSuccess &&
      paymentsData.data.payers.some(
        (v) => !!v.profile.corporate && v.profile.companyType === 'bank' && v.amount > 5900
      )
    )
  }, [paymentsData])

  const description = useMemo(() => {
    let capped = 'capped'
    if (uncappedPayments) {
      capped = 'uncapped'
    }

    const documents = t(`components.uploadDocuments.add.description.${capped}.documents`, {
      returnObjects: true
    }) as string[]

    return {
      main: t(`components.uploadDocuments.add.description.${capped}.main`),
      documents,
      end: t(`components.uploadDocuments.add.description.${capped}.end`)
    }
  }, [t, uncappedPayments])

  return (
    <Space direction='vertical' className='w-100 py-3'>
      <Col>
        <Title level={5}>{t('components.uploadDocuments.add.title')}</Title>
        <Text type='secondary' className='d-block mb-2'>
          {description.main}
        </Text>
        {description.documents.map((item) => (
          <Text key={item} className='d-block ms-2'>
            • {item}
          </Text>
        ))}
        <Text type='secondary' className='d-block my-2'>
          {description.end}
        </Text>
      </Col>

      <CloudinaryDragger
        multiple
        accept='application/pdf, image/*'
        draggerText={t('components.uploadDocuments.add.chooseFile')}
        draggerHint={t('components.uploadDocuments.add.fileHint')}
        onCreateAfterUpload={handleCreateAfterCloudinaryUpload}
      />
      {uploads && uploads.length > 0 && (
        <>
          <Col>
            <Title level={5} className='mt-3'>
              {t('components.uploadDocuments.list.title')}
            </Title>
            <Text type='secondary'>{t('components.uploadDocuments.list.description')}</Text>
          </Col>

          <List
            itemLayout='horizontal'
            dataSource={uploads}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <Button
                    key={`edit-${index}`}
                    type='text'
                    onClick={() => {
                      openEditPanel(item)
                    }}>
                    <EditOutlined />
                  </Button>,
                  <Button
                    key={`delete-${index}`}
                    danger
                    type='text'
                    onClick={() => {
                      removeItem(item)
                    }}>
                    <DeleteOutlined />
                  </Button>
                ]}>
                <List.Item.Meta title={item.name} description={item.file.readableType} />
              </List.Item>
            )}
          />
        </>
      )}
    </Space>
  )
}

export default withApp(UploadDocuments)
