import FormComponent from '@components/forms/form'
import {FormObject, FormState} from '@components/forms/form.t'
import {withApp} from '@store/app'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {FormikProps} from 'formik'
import {FormPanelProps} from './form'
import {Button, Drawer} from 'antd'
import {useInmemoriServices} from '@services'
import {
  Cart,
  CartItem,
  CartItemSearchContext,
  CartItemStatus,
  DatadgoErrorScope,
  PriceBundle,
  SearchArticlesItem,
  SearchSaleArticles,
  Service
} from '@shared/interfaces'
import ResultView, {PanelHeader} from './resultView'
import {PanelsKeys} from '@libs/panels/panels.t'
import {useCallbackRef} from 'use-callback-ref'
import {LookupItem} from '@shared/erp-api'
import {useRouter} from 'next/router'

export enum ResultViewType {
  SALE_ARTICLE = 'saleArticle',
  SUPPLIER_ARTICLE = 'supplierArticle'
}

export interface ProductFinderPanelProps extends FormPanelProps {
  cart: Cart
  cartItem?: CartItem
  searchContext?: CartItemSearchContext
  replaceAction?: boolean
}

const ProductFinder: React.FC<ProductFinderPanelProps> = ({
  Panels,
  Datadog,
  toast,
  t,
  action,
  cartItem,
  cart,
  searchContext,
  replaceAction = false
}) => {
  const erpApi = useInmemoriServices().erpApiService
  const router = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  const [addInProgress, setAddInProgress] = useState(false)
  const [formState, setFormState] = useState<FormState>({
    isDirty: false,
    isValid: false,
    isSubmitting: false
  })
  const [resultType, setResultType] = useState<ResultViewType>(ResultViewType.SALE_ARTICLE)
  const [service, setService] = useState<Service | undefined>()

  const formikRef = useCallbackRef<FormikProps<FormObject>>(null, (node) => {
    if (node) {
      if (
        node.dirty !== formState.isDirty ||
        node.isValid !== formState.isValid ||
        node.isSubmitting !== formState.isSubmitting
      )
        setFormState({isDirty: node.dirty, isValid: node.isValid, isSubmitting: node.isSubmitting})
    }
  })
  const [saleArticles, setSaleArticles] = useState<SearchArticlesItem[] | null>(null)
  const [supplierArticles, setSupplierArticles] = useState<SearchArticlesItem[] | null>(null)

  const results = useMemo(
    () => (resultType === ResultViewType.SALE_ARTICLE ? saleArticles : supplierArticles),
    [resultType, saleArticles, supplierArticles]
  )

  useEffect(() => {
    if (searchContext?.service && !formikRef.current?.values.service) {
      formikRef.current?.setFieldValue('service', searchContext.service)
    }
    if (searchContext?.product) {
      formikRef.current?.setFieldValue('product', searchContext.product)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikRef.current])

  const addToCart = useCallback(
    ({
      saleArticle,
      supplierArticle,
      price,
      searchContext
    }: {
      saleArticle: string
      supplierArticle?: string
      price: PriceBundle
      searchContext: CartItemSearchContext
    }) => {
      if (addInProgress) return
      setAddInProgress(true)

      if (cartItem) {
        erpApi
          .replaceCartItemArticle({
            saleArticle,
            supplierArticle,
            cartItem,
            price,
            searchContext,
            isFromCatalog: false
          })
          .then(() => {
            window.Panels.close(PanelsKeys.PRODUCT_FINDER)
            window.app.toast.success()
            window.SM?.sub.refresh('cart')
            setAddInProgress(false)
          })
      } else {
        erpApi
          .addToCart(
            [{saleArticle, supplierArticle, price, isFromCatalog: false, searchContext}],
            cart?._id
          )
          .then(() => {
            window.Panels.close(PanelsKeys.PRODUCT_FINDER)
            window.app.toast.success()
            window.SM?.sub.refresh('cart')
            setAddInProgress(false)
          })
      }
    },
    [addInProgress, cartItem, erpApi, cart?._id]
  )

  const searchSupplierArticles = useCallback(
    (searchItem: SearchArticlesItem, force = false) => {
      setIsLoading(true)
      erpApi
        .searchSupplierArticles(searchItem, cart._id)
        .then(({data: {data}}) => {
          setResultType(ResultViewType.SUPPLIER_ARTICLE)
          const articles = data.articles
          if (articles.length === 1)
            addToCart({
              saleArticle: articles[0].saleArticle._id,
              supplierArticle: articles[0].supplierArticle._id,
              price: articles[0].price,
              searchContext: articles[0].searchContext as unknown as CartItemSearchContext
            })
          else if (!force)
            addToCart({
              saleArticle: searchItem.saleArticle._id,
              price: searchItem.price,
              searchContext: searchItem.searchContext
            })
          else {
            setService(data.service)
            setSupplierArticles(articles)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [addToCart, cart._id, erpApi]
  )

  const searchSaleArticles = useCallback(() => {
    setIsLoading(true)
    const {product, options, service} = formikRef.current?.values as Pick<
      SearchSaleArticles,
      'product' | 'options' | 'service'
    >

    erpApi
      .searchSaleArticles({
        product,
        options,
        service,
        cart,
        action
      })
      .then(({data: {data}}) => {
        setService(data.service)
        setSaleArticles(data.articles)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [action, cart, erpApi, formikRef])

  const onArticleClicked = useCallback(
    async (article?: LookupItem | SearchArticlesItem) => {
      if (!article) return

      const searchItem = article as SearchArticlesItem

      if (
        (resultType === ResultViewType.SALE_ARTICLE && !searchItem.price) ||
        searchItem.price?.estimate
      ) {
        searchSupplierArticles(searchItem)
        return
      }

      await addToCart({
        saleArticle: searchItem.saleArticle._id,
        supplierArticle: searchItem.supplierArticle?._id,
        price: searchItem.price,
        searchContext: searchItem.searchContext
      })
    },
    [addToCart, resultType, searchSupplierArticles]
  )

  const onDrawerClose = useCallback(() => {
    if (resultType === ResultViewType.SALE_ARTICLE) setSaleArticles(null)
    else {
      setResultType(ResultViewType.SALE_ARTICLE)
      setSupplierArticles(null)
    }
  }, [resultType])

  useEffect(() => {
    if (cartItem) {
      if (cartItem.status === CartItemStatus.PENDING_SUPPLIER && !replaceAction)
        setTimeout(() => {
          searchSupplierArticles(cartItem as unknown as SearchArticlesItem, true)
        }, 200)
    }
  }, [cartItem, replaceAction, searchSupplierArticles])

  const headerTitle = useMemo(() => {
    switch (resultType) {
      case ResultViewType.SALE_ARTICLE:
        return 'panel.productFinder.saleArticles'
      case ResultViewType.SUPPLIER_ARTICLE:
        return 'panel.productFinder.supplierArticles'
    }
  }, [resultType])

  const sendBugReport = useCallback(() => {
    const message =
      resultType === ResultViewType.SALE_ARTICLE
        ? 'Product Finder - Missing sale articles'
        : 'Product Finder - Missing supplier articles'

    Datadog.trackError(new Error(message), {
      scope: DatadgoErrorScope.MARKETPLACE,
      view: 'Product Finder',
      type: resultType,
      cart,
      cartItem,
      ...formikRef.current?.values
    })

    toast.success('panel.productFinder.bugReportSent')
  }, [Datadog, cart, cartItem, formikRef, resultType, toast])

  const openService = useCallback(() => {
    window.Panels.close(PanelsKeys.PRODUCT_FINDER)

    router.push(`/domain/projects/${cart.project}/services/${service?._id}`).then(() => {
      router.reload()
    })
  }, [cart.project, router, service])

  return (
    <div>
      <div>
        <PanelHeader
          panelKey={PanelsKeys.PRODUCT_FINDER}
          title={t('panel.productFinder.productSearch') as string}
          onClose={() => Panels.close(PanelsKeys.PRODUCT_FINDER)}>
          <Button
            type='primary'
            disabled={isLoading || !formState.isValid}
            loading={isLoading}
            onClick={() => {
              if (formikRef.current) {
                formikRef.current.submitForm()
              }
            }}>
            {t('components.lookup-component.form-filter.search')}
          </Button>
        </PanelHeader>

        <FormComponent
          formId='addProduct'
          disabled={false}
          innerRef={formikRef}
          data={{
            project: cart.project as unknown as FormObject,
            searchContext: searchContext as unknown as FormObject
          }}
          onSubmit={() => {
            searchSaleArticles()
          }}
        />

        <Drawer width={600} closable={false} open={!!results} onClose={onDrawerClose}>
          {results && (
            <ResultView
              resultType={resultType}
              service={service}
              result={results}
              openService={openService}
              onSendError={sendBugReport}
              onItemClicked={onArticleClicked}
              headerTitle={headerTitle}
              onClose={(key: PanelsKeys) => {
                onDrawerClose()
                window.Panels.close(key)
              }}
            />
          )}
        </Drawer>
      </div>
    </div>
  )
}

export default withApp(ProductFinder)
