import * as Yup from 'yup'
import React from 'react'
import {atPath} from '@libs/utils'
import {Button, Input, InputNumber, Space} from 'antd'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useField, useFormikContext} from 'formik'
import _ from 'lodash'
import formatNumber from '@libs/helpers/numbers'
import FormItem from '../form-item/form-item'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

interface TextInputComponentProps {
  key: string
  label: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  min?: number
  max?: number
  step?: number
  suffix?: string
  prefix?: string
}

const InputWrapper = (props: {
  name: string
  type: string
  value?: unknown
  disabled?: boolean
  onChange?: (v: string | number | null) => void
  min?: number
  max?: number
  step?: number
  suffix?: string
  prefix?: string
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {type, value, onChange, ...filteredProps} = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formField, meta] = useField(props.name)

  if (type === 'number') {
    return (
      <InputNumber
        {...filteredProps}
        defaultValue={meta.initialValue ?? null}
        value={formField.value ?? null}
        decimalSeparator=','
        formatter={(value) => (value == null ? '' : formatNumber(value))}
        parser={(value) => {
          if (!value || value.trim() === '') return null
          return Number(value.replace(',', '.').replace(' ', ''))
        }}
        onChange={(value) => onChange?.(value)}
      />
    )
  } else if (type === 'password')
    return (
      <Input.Password
        {...filteredProps}
        value={formField.value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    )
  else if (type === 'textareainfinity')
    return (
      <Input.TextArea
        {...filteredProps}
        value={formField.value}
        autoSize={{minRows: 2, maxRows: Infinity}}
        onChange={(e) => onChange?.(e.target.value)}
      />
    )
  else if (type === 'textarea')
    return (
      <Input.TextArea
        {...filteredProps}
        value={formField.value}
        autoSize={{minRows: 2, maxRows: 20}}
        onChange={(e) => onChange?.(e.target.value)}
      />
    )
  else if (type === 'url')
    return (
      <Space.Compact style={{width: '100%'}}>
        <Input
          {...filteredProps}
          value={formField.value}
          onChange={(e) => onChange?.(e.target.value)}
        />
        <Button
          href={formField.value}
          className='mx-1'
          target='_blank'
          shape='circle'
          icon={
            <FontAwesomeIcon
              size='sm'
              icon={['fas', 'external-link-alt']}
              className='m-1 cursor-pointer'
            />
          }
        />
      </Space.Compact>
    )

  return (
    <Input
      {...filteredProps}
      value={formField.value}
      type={type}
      onChange={(e) => onChange?.(e.target.value)}
    />
  )
}

const TextInputComponent: React.FC<FieldComponentProps<TextInputComponentProps>> = ({field}) => {
  const formProps = useFormikContext()
  const [formikField] = useField<string | number>(field)

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      <InputWrapper
        type={field.type}
        name={formikField.name}
        disabled={field.disabled}
        onChange={(v) => formProps.setFieldValue(formikField.name, v)}
        min={field.min}
        max={field.max}
        step={field.step}
        suffix={field.suffix}
      />
    </FormItem>
  )
}

const TextInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) {
        if (field.type === 'number') return Number(value)
        return value
      }
      if (field.type === 'number')
        return field.defaultValue ? Number(field.defaultValue) : undefined
      return field.defaultValue || ''
    },
    validationSchema() {
      let schema: Yup.NumberSchema<number | undefined | null> | Yup.StringSchema
      switch (field.type) {
        case 'number':
          schema = Yup.number().nullable()
          break
        case 'email':
          schema = Yup.string().email()
          break
        case 'url':
          schema = Yup.string().url()
          break
        default:
          schema = Yup.string()
      }

      if (field.required) schema = schema.required()

      return {[field.key]: schema}
    },
    generateComponent() {
      return <TextInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default TextInput
