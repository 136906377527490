import ButtonWidget from './button-widget/button-widget'
import CartWidget from './cart-widget/cart-widget'
import DomainJsonWidget from './domain-json'
import HtmlWidget from './html-widget'
import TaskManagementWidget from './task-management-widget'
import PDFWidget from './pdf-widget/pdf-widget'
import ImportReportWidget from './import-report'
import RecoveryTimelineWidget from './recovery-timeline'

const Widgets = {
  button: ButtonWidget,
  cart: CartWidget,
  taskManagement: TaskManagementWidget,
  'html-view': HtmlWidget,
  pdf: PDFWidget,
  domainJson: DomainJsonWidget,
  importReport: ImportReportWidget,
  recoveryTimeline: RecoveryTimelineWidget
}

export default Widgets
