import {Card, Space} from 'antd'
import React, {useMemo} from 'react'
import CartSubCategory from './cart-subcategory'
import {Cart, CartCategory} from '@shared/interfaces'

interface CartCategoryComponentProps {
  cart: Cart
  category: CartCategory
}

const CartCategoryComponent: React.FC<CartCategoryComponentProps> = ({
  cart,
  category
}: CartCategoryComponentProps) => {
  const defaultBack = '#9B9B9B'
  const defautlFont = '#3E3E3F'

  const back = useMemo(() => (category.color ? category.color : defaultBack), [category])
  const font = useMemo(() => (category.fontColor ? category.fontColor : defautlFont), [category])

  return (
    <Card
      title={category.name}
      styles={{
        header: {
          color: font,
          fontSize: '1.1rem',
          background: back,
          paddingBottom: '5px'
        },
        body: {
          padding: '0px'
        }
      }}>
      <Space direction='vertical' className='w-100'>
        {category.subCategories.map((sub, index) => (
          <CartSubCategory
            key={index}
            cart={cart}
            category={category}
            subCategory={sub}
            backColor={back}
            fontColor={font}
          />
        ))}
      </Space>
    </Card>
  )
}

export default CartCategoryComponent
