import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react'
import _ from 'lodash'
import {CatalogArticle, CatalogContext, CatalogData, CatalogState, Filters} from './context'
import {Cart, CartItem, House, Option, Product} from '@shared/interfaces'
import {useLookupQuery} from '@queries'
import {LookupOptions} from '@shared/erp-api'
import {getDefaultOptions} from '@components/catalog/utils'
import {useGetCatalogQuery} from '@queries/articles'

interface CatalogProviderProps extends PropsWithChildren {
  onClose?: () => void
  product?: string
  cart?: Cart
  cartItem?: CartItem
}

const CatalogProvider: React.FC<CatalogProviderProps> = ({
  children,
  product: initialProduct,
  onClose,
  cartItem,
  cart
}) => {
  const [data, setData] = useState<CatalogData>({
    products: [],
    articles: [],
    houses: []
  })
  const [filters, setFilters] = useState<Filters>({
    ...(initialProduct && {product: initialProduct}),
    extended: true,
    options: []
  })
  const [sidebarHidden, setSidebarHidden] = useState<boolean>(false)
  const [options, setOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [extended, setExtended] = useState<boolean>(false)
  const [article, setArticle] = useState<CatalogArticle>()
  const [products, setProducts] = useState<Product[]>([])
  const [optionsSelected, setOptionsSelected] = useState<{[key: string]: string}>({
    ...getDefaultOptions(cart, initialProduct)
  })

  const hasProject = useMemo(() => Boolean(cart), [cart])

  const resetFilters = () => setFilters({})
  const unsetFilter = (key: string) => setFilters({..._.omit(filters, key)})

  const {
    data: catalogData,
    isSuccess: catalogQuerySuccess,
    isLoading
  } = useGetCatalogQuery<Filters, CatalogData>(
    {
      ...(cart ? {cart: cart._id} : {}),
      ...(initialProduct ? {product: initialProduct} : {}),
      ...(!_.isEmpty(optionsSelected)
        ? {
            options: _.chain(optionsSelected)
              .omitBy(_.isNil)
              .toPairs()
              .map(([option, value]) => ({option, value: `${value}`}))
              .concat(filters.options || [])
              .uniqBy('option')
              .value()
          }
        : {}),
      ..._.omit(filters, 'options')
    },
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (catalogQuerySuccess) {
      setData((state) => ({
        ...state,
        ...catalogData
      }))
      setFilters((state) => ({
        ...state,
        product: state.product || catalogData.state?.product,
        house: state.house || catalogData.state?.house
      }))
      setProducts(catalogData.products)
    }
  }, [catalogData, catalogQuerySuccess])

  const optionsLookupParams = useMemo<LookupOptions | undefined>(() => {
    if (catalogData && catalogQuerySuccess) {
      return {
        q: {
          _id: {
            $in:
              (catalogData?.products || []).find(({_id}) => _id === filters.product)?.options || []
          },
          isCatalog: true,
          deleted: false
        }
      }
    }
  }, [catalogData, catalogQuerySuccess, filters.product])

  const {data: lookupOptionsData, isSuccess: lookupOptionsSuccess} = useLookupQuery<Option>(
    'options',
    {...optionsLookupParams},
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(optionsLookupParams)
    }
  )

  useEffect(() => {
    if (lookupOptionsSuccess) {
      setOptions(lookupOptionsData.data)
    }
  }, [lookupOptionsData, lookupOptionsSuccess])

  const {data: lookupHousesData, isSuccess: lookupHousesSuccess} = useLookupQuery<House>(
    'houses',
    {},
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (lookupHousesSuccess) {
      setData((state) => ({
        ...state,
        houses: lookupHousesData.data
      }))
    }
  }, [lookupHousesData, lookupHousesSuccess])

  const context: CatalogState = {
    data,
    filters,
    sidebarHidden,
    options,
    loading,
    extended,
    article,
    products,
    setData,
    setFilters,
    setSidebarHidden,
    setOptions,
    setLoading,
    setExtended,
    setArticle,
    resetFilters,
    unsetFilter,
    isLoading,
    hasProject,
    onClose,
    cart,
    setProducts,
    optionsSelected,
    setOptionsSelected,
    cartItem
  }

  return <CatalogContext.Provider value={{...context}}>{children}</CatalogContext.Provider>
}

export default CatalogProvider
