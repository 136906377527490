import {CheckCircleFilled} from '@ant-design/icons'
import Uploads from '@components/uploads'
import {AppContext, withApp} from '@store/app'
import {Button, Col, Row} from 'antd'
import React from 'react'

export interface DocumentUploadProps extends AppContext {
  projectId: string
  onSuccess?: () => void
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({
  projectId,
  onSuccess,
  t
}: DocumentUploadProps) => {
  return (
    <Col className='mt-4 hidden-scroll-container' style={{maxHeight: '670px', overflow: 'scroll'}}>
      <Uploads projectId={projectId} />
      <Row justify='end' className='mt-4'>
        <Button type='default' onClick={onSuccess} icon={<CheckCircleFilled />}>
          {t('panel.cartValidation.steps.documents.button')}
        </Button>
      </Row>
    </Col>
  )
}

export default withApp(DocumentUpload)
