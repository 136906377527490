/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from 'react'
import {Upload, message} from 'antd'
import type {RcFile, UploadProps, UploadChangeParam, UploadFile} from 'antd/es/upload/interface'
import {InboxOutlined} from '@ant-design/icons'

const {Dragger} = Upload

const CLOUDINARY_CLOUD_NAME = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME
const CLOUDINARY_UPLOAD_PRESET = process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET

interface CloudinaryDraggerProps
  extends Omit<UploadProps, 'customRequest' | 'onChange' | 'fileList' | 'onRemove'> {
  onUploadSuccess?: (response: any) => void
  onUploadError?: (error: any) => void
  onCreateAfterUpload?: (cloudinaryResponse: any, file: UploadFile) => Promise<void>
  onFileListChange?: (fileList: UploadFile[]) => void
  draggerText?: string | null
  draggerHint?: string | null
}

const CloudinaryDragger: React.FC<CloudinaryDraggerProps> = ({
  onUploadSuccess,
  onUploadError,
  onCreateAfterUpload,
  onFileListChange,
  draggerText,
  draggerHint,
  multiple = false,
  accept,
  ...restProps
}) => {
  const [internalFileList, setInternalFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    onFileListChange?.(internalFileList)
  }, [internalFileList, onFileListChange])

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const {file, onProgress, onSuccess, onError} = options

    if (!CLOUDINARY_UPLOAD_PRESET || !CLOUDINARY_CLOUD_NAME) {
      const errMsg = 'Cloudinary upload preset or cloud name not found'
      onError?.(new Error(errMsg))
      onUploadError?.(errMsg)
      return
    }

    try {
      const formData = new FormData()
      formData.append('file', file as RcFile)
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)

      const xhr = new XMLHttpRequest()
      xhr.open(
        'POST',
        `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`,
        true
      )

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable && onProgress) {
          const percentComplete = (event.loaded / event.total) * 100
          onProgress({percent: Math.round(percentComplete)})
        }
      })

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText)
            onSuccess?.(response)
            onUploadSuccess?.(response)
          } else {
            const errMsg = xhr.responseText || 'Upload error'
            onError?.(new Error(errMsg))
            onUploadError?.(errMsg)
          }
        }
      }

      xhr.send(formData)
    } catch (error) {
      const err = error instanceof Error ? error : new Error(String(error))
      onError?.(err)
      onUploadError?.(err)
    }
  }

  const handleChange = async (info: UploadChangeParam<UploadFile>) => {
    const updatedList = info.fileList
    setInternalFileList(updatedList)

    if (info.file.status === 'done') {
      if (onCreateAfterUpload && info.file.response) {
        try {
          await onCreateAfterUpload(info.file.response, info.file)

          setInternalFileList((prevList) => prevList.filter((f) => f.uid !== info.file.uid))
        } catch (err) {
          const errorMsg = err instanceof Error ? err.message : String(err)
          message.error(`Failed to save ${info.file.name} to your API: ${errorMsg}`)
          setInternalFileList((prevList) =>
            prevList.map((f) => (f.uid === info.file.uid ? {...f, status: 'error' as const} : f))
          )
        }
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} failed to upload.`)
    }
  }

  return (
    <Dragger
      fileList={internalFileList}
      onChange={handleChange}
      customRequest={customRequest}
      multiple={multiple}
      accept={accept}
      {...restProps}>
      <p className='ant-upload-drag-icon'>
        <InboxOutlined />
      </p>
      <p className='ant-upload-text'>
        {draggerText ?? 'Click or drag file to this area to upload'}
      </p>
      <p className='ant-upload-hint'>{draggerHint ?? 'Support for a single or bulk upload.'}</p>
    </Dragger>
  )
}

export default CloudinaryDragger
