import * as Yup from 'yup'
import React, {useMemo} from 'react'
import {atPath, relativePath} from '@libs/utils'

import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useField, useFormikContext} from 'formik'
import _ from 'lodash'
import {useDomain} from '@hooks/use-domain'
import {LookupItem} from '@shared/erp-api'
import {BaseEntity, FieldDomain} from '@shared/interfaces'
import Parser from './parser'
import EntityPickerSelect from './entity-picker-select'
import FormItem from '../form-item/form-item'

type EntityPickerInputComponentProps = Pick<
  FieldDomain,
  | '_id'
  | 'key'
  | 'label'
  | 'name'
  | 'domain'
  | 'disabled'
  | 'createContext'
  | 'limit'
  | 'hideCTAs'
  | 'queryParams'
>

// Dropdown items count - 4
const LOOKUP_TABLE_LIMIT = 4

const EntityPickerInputComponent: React.FC<
  FieldComponentProps<EntityPickerInputComponentProps>
> = ({field}) => {
  // Formik field

  // field's Form props
  const formProps = useFormikContext<BaseEntity>()

  // We need the domainKey + parsedLookupOptions
  const {domainKey, lookupOptions, mainItem} = useMemo(() => {
    // Parser uses handlebars in order to parse the lookupOptions (q, domain, matchLookup, sort)
    const parser: Parser = new Parser(field.name, formProps.values).setField(field).parseField()
    return {
      domainKey: parser.domain,
      lookupOptions: parser.getLookupOptions(),
      mainItem: formProps.values
    }
  }, [field, formProps.values])

  // returns the domain object by domainKey (we'll need the pluralKey for fetching data)
  const domain = useDomain(domainKey)
  const fieldPath = relativePath(field.name)

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      {domain && (
        <EntityPickerSelect
          name={field.name}
          domain={domain}
          lookupOptions={lookupOptions || {}}
          id={field._id}
          disabled={field.disabled}
          mainItem={mainItem}
          createContext={field.createContext}
          hideCTAs={field.hideCTAs}
          values={formProps.values}
          instance={fieldPath ? atPath(formProps.values, fieldPath) : null}
          limit={domain.lookupOptions?.limit || field.limit || LOOKUP_TABLE_LIMIT}
          queryParams={field.queryParams}
        />
      )}
    </FormItem>
  )
}

const EntityPickerInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const defaultValue = () => {
        if (field.defaultValue && data) {
          return field.defaultValue.startsWith('$')
            ? atPath(data, field.defaultValue.replace('$', ''))
            : field.defaultValue
        }
      }
      const value = (data && atPath(data, field.key)) || defaultValue()

      return value
    },
    validationSchema() {
      const schema: Yup.MixedSchema<Yup.AnyObject | null | undefined> = Yup.mixed()

      return {[field.key]: field.required ? schema.required() : schema.nullable()}
    },
    generateComponent() {
      return <EntityPickerInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default EntityPickerInput
