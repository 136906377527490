import {FormObject} from '@components/forms/form.t'
import {Position, Node as RFNode, XYPosition} from 'react-flow-renderer/nocss'

const DefaultNodeSize = {
  width: 200,
  height: 50
}

export interface NodeConfig {
  size: {width: number; height: number}
  color?: string
  name: string
  formId: string
}

enum NodeType {
  start = 'start',
  drop = 'drop',
  saleArticle = 'saleArticle',
  supplierArticle = 'supplierArticle',
  document = 'document',
  documentRaw = 'documentRaw',
  product = 'product',
  productOptions = 'productOptions',
  domainTask = 'domainTask',
  service = 'service',
  communication = 'communication',
  split = 'split',
  ruleSplit = 'ruleSplit',
  multiRuleSplit = 'multiRuleSplit'
}

export interface NodeDataLane {
  id?: string
  label?: string
  ruleValue?: {
    type: {type: string}
    arrayOf: string
    operator: FormObject
    value: FormObject
  }
}

export interface NodeData {
  label?: string
  content?: string
  document?: FormObject
  article?: FormObject
  saleArticle?: FormObject
  supplierArticle?: FormObject
  domainTask?: FormObject
  product?: FormObject
  communication?: {
    template?: FormObject
    step?: FormObject
    recipient?: FormObject
  }
  service?: {
    type: FormObject
    data: FormObject
  }
  serviceType?: FormObject
  options?: [
    {
      option: FormObject
      value: FormObject
    }
  ]
  lanes?: NodeDataLane[]
  fact?: string
  path?: string
  params?: FormObject
  else?: boolean
  rule?: FormObject
  optional?: boolean
}

export interface Node extends RFNode {
  id: string
  type: NodeType
  data: NodeData
  position: XYPosition
  targetPosition?: Position
  sourcePosition?: Position
}

export interface NodeContext {
  node: Node
  inputEdge?: Edge
  nodes: Node[]
  edges: Edge[]
  outputNode?: Node
  lanes?: LaneContext[]
  drops?: string[]
}

export interface LaneContext extends NodeDataLane {
  inputEdge?: Edge
  nodes: Node[]
  edges: Edge[]
  outputNode?: Node
  drops?: string[]
}

export interface Edge {
  id: string
  label?: string
  target: string
  source: string
  animated?: boolean
}

export interface NodeConfigs {
  [key: string]: NodeConfig
}

const NodeConfigs: NodeConfigs = {
  saleArticle: {
    size: DefaultNodeSize,
    color: 'orange',
    name: 'Sale Article',
    formId: '66796aabf65794b4d9d96b9d'
  },
  supplierArticle: {
    size: DefaultNodeSize,
    color: 'olive',
    name: 'Supplier Article',
    formId: '66796aaff65794b4d9d96bc4'
  },
  document: {
    size: DefaultNodeSize,
    color: 'magenta',
    name: 'Document',
    formId: '6295fdac0b0a21a05602779f'
  },
  documentRaw: {
    size: DefaultNodeSize,
    color: 'DarkMagenta',
    name: 'Document Raw',
    formId: '62a1a7e4d9a10a013fa079e3'
  },
  product: {
    size: DefaultNodeSize,
    color: 'cyan',
    name: 'Product',
    formId: '6295fd5a0b0a21a056027782'
  },
  productOptions: {
    size: DefaultNodeSize,
    color: 'azure',
    name: 'Product Options',
    formId: '6295fdc00b0a21a0560277ad'
  },
  service: {
    size: DefaultNodeSize,
    color: 'orange',
    name: 'Service',
    formId: '6407021a12885215a5a62e69'
  },
  domainTask: {
    size: DefaultNodeSize,
    color: 'brown',
    name: 'Domain Task',
    formId: '63f5f77731b7d971ece021ec'
  },
  communication: {
    size: DefaultNodeSize,
    color: 'Aquamarine',
    name: 'Communication',
    formId: '67c6fb8f095458dbb27e08cb'
  },
  split: {size: DefaultNodeSize, color: 'red', name: 'Split', formId: '6295fdfd0b0a21a0560277d7'},
  ruleSplit: {
    size: DefaultNodeSize,
    color: 'blue',
    name: 'Rule Split',
    formId: '6295fddf0b0a21a0560277bb'
  },
  multiRuleSplit: {
    size: DefaultNodeSize,
    color: 'green',
    name: 'Multi Value Split',
    formId: '6295fdf20b0a21a0560277c9'
  }
}

const DataNodes = [
  NodeType.saleArticle,
  NodeType.supplierArticle,
  NodeType.document,
  NodeType.documentRaw,
  NodeType.product,
  NodeType.productOptions,
  NodeType.service,
  NodeType.domainTask,
  NodeType.communication
]

const FlowNodes = [NodeType.split, NodeType.ruleSplit, NodeType.multiRuleSplit]

export {NodeConfigs, DefaultNodeSize, DataNodes, FlowNodes, NodeType}
