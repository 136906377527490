import {FormDomain, FormLayoutRow} from '@shared/interfaces'
import {Col, Row, Typography} from 'antd'
import React, {Dispatch, useMemo} from 'react'
import {FieldWrapper} from '../fields/fields.t'
import {FormLayoutHideHandler} from '../handlers/form-layout-handlers'
import {ValidationAction} from '../form.t'
import FormColumnLayout from './form-column'
import {useFieldsHidden} from '@hooks/use-field-hidden'

const {Title} = Typography

interface FormRowLayoutProps {
  form: FormDomain
  row: FormLayoutRow
  fields: FieldWrapper[]
  dispatchValidation: Dispatch<ValidationAction>
}

const FormRowLayout: React.FC<FormRowLayoutProps> = ({form, fields, row, dispatchValidation}) => {
  const {columns, hidden, showLabel, label, className} = row

  const rowFields = useMemo(() => {
    const ids = columns?.map((column) => column.components?.map((component) => component.id)).flat()
    return fields.filter((field) => ids?.includes(field?.domain._id))
  }, [columns, fields])

  const fieldsHidden = useFieldsHidden(rowFields, form)

  const shouldHide = useMemo(() => !Object.values(fieldsHidden).some((v) => !v), [fieldsHidden])

  if (!columns || columns.length == 0) return null

  if (row.id === 'fe28840e-8dfb-4c71-826f-238407c506e1') {
    console.log(rowFields.at(0)?.hidden)
  }

  return (
    <FormLayoutHideHandler form={form} hidden={hidden || false}>
      {!shouldHide && (
        <Col className='mt-2 mb-2'>
          {showLabel && label && (
            <Row className='my-2'>
              <Title level={5}>{label}</Title>
            </Row>
          )}
          <Row
            gutter={16}
            wrap={false}
            className={`w-100 ${className}`}
            style={{marginLeft: '0px'}}>
            {columns.map((column) => (
              <FormColumnLayout
                key={column.id}
                form={form}
                column={column}
                fields={fields}
                dispatchValidation={dispatchValidation}
              />
            ))}
          </Row>
        </Col>
      )}
    </FormLayoutHideHandler>
  )
}

export default FormRowLayout
