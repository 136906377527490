import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Action, ErpTable} from '@shared/interfaces'
import {Table as AntdTable, Collapse, ConfigProvider} from 'antd'
import {ColumnType} from 'antd/lib/table/interface'
import CollapseHeader from './header'
import {Crud} from './table.t'
import {resolveColumns} from './helpers'
import useRowActions from './hooks/use-row-actions'
import {FormObject} from '@components/forms/form.t'
import _ from 'lodash'
import useActionHandler from '@hooks/use-action-handler'
import useRowSelection from './hooks/use-row-selection'
import {ExecSync} from '@libs/exec'
import {useGetByIdQuery} from '@queries'

interface TableProps {
  table: ErpTable
  data: FormObject[]
  crud?: Crud
  nested?: boolean
}

const Table: React.FC<TableProps> = ({table, data, crud, nested}) => {
  const [columns, setColumns] = useState<ColumnType<FormObject>[]>([])

  const {data: nestedTable} = useGetByIdQuery<ErpTable>(
    'tables',
    (table.nestedTable as ErpTable)?._id || (table.nestedTable as string) || '',
    {
      query: {
        enabled: !!table.nested && !!table.nestedTable
      }
    }
  )

  const {
    header: headerActions,
    row: rowActions,
    multiSelection: multiSelectionActions
  } = useMemo(
    () => ({
      row: [],
      header: [],
      multiSelection: [],
      ..._.groupBy<Action>(table.actions, 'tableLocation')
    }),
    [table.actions]
  )

  const actionsColumn: ColumnType<FormObject> | null = useRowActions(table, rowActions, crud)

  const {rowSelection, selectedItems, setSelectedItems} = useRowSelection()

  const actionHandler = useActionHandler(table.domain?.keyPlural, {
    allItems: data,
    selectedItems
  })

  const normalizedData = useMemo(
    () =>
      data?.map(({_id, key, ...rest}) => ({
        _id,
        key: key || _id,
        ...rest
      })) || [],
    [data]
  )

  useEffect(() => {
    resolveColumns(table, setColumns, actionsColumn, crud)
  }, [table, actionsColumn, crud])

  const rowExpandable = useCallback(
    (record: FormObject) => {
      if (!table.nested) return false
      if (!table.isRowExpandable) return true

      return ExecSync(table.isRowExpandable, {record})
    },
    [table.isRowExpandable, table.nested]
  )

  const expandedRowRender = useCallback(
    (record: FormObject, index: number, indent: unknown, expanded: boolean) => {
      if (!expanded) return null
      if (!table.nested) return null
      if (!nestedTable) return null
      if (!table.fetchExpandedData) return null

      const data = ExecSync(table.fetchExpandedData, {record})

      return (
        <div className='p-0 m-0' style={{position: 'relative'}}>
          <div
            style={{
              paddingLeft: '2rem',
              paddingTop: '5px'
            }}>
            <Table table={nestedTable} data={data} nested />
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '1rem',
              background: 'linear-gradient(to bottom, rgba(96, 98, 102, 0.15), transparent)',
              zIndex: 10,
              pointerEvents: 'none'
            }}
          />
        </div>
      )
    },
    [table, nestedTable]
  )

  if (nested)
    return (
      <AntdTable
        rowSelection={!table.static ? rowSelection : undefined}
        columns={columns}
        dataSource={normalizedData}
        {...(!table.pagination && {pagination: false})}
        {...(table.nested && {expandable: {rowExpandable, expandedRowRender}})}
      />
    )

  return (
    <Collapse activeKey={table._id}>
      <Collapse.Panel
        showArrow={false}
        key={table._id}
        header={
          <CollapseHeader
            onSave={crud?.onAdd}
            title={table.name}
            actions={{headerActions, multiSelectionActions}}
            actionHandler={actionHandler}
            createForm={table.forms?.create}
            add={table.extendable}
            showMultiselectActions={Boolean(selectedItems.length)}
            onActionSuccess={() => setSelectedItems([])}
          />
        }>
        <AntdTable
          rowSelection={!table.static ? rowSelection : undefined}
          columns={columns}
          dataSource={normalizedData}
          {...(!table.pagination && {pagination: false})}
          {...(table.nested && {expandable: {rowExpandable, expandedRowRender}})}
        />
      </Collapse.Panel>
    </Collapse>
  )
}

export default Table
