import {AppContext, withApp} from '@store/app'
import {Col, Row, Typography} from 'antd'
import React, {useCallback, useMemo, useState} from 'react'
import CartValidationHeader from './header'
import ProjectValidation from './projectValidation'
import SuppliersValidation from './suppliersValidation'
import CartFinancingPlan from '@components/cart-financing-plan'
import DocumentUpload from './documentUpload'
import {PanelsKeys} from '@libs/panels/panels.t'
import {Cart, CartCategory, ErpDomain} from '@shared/interfaces'
import {saveDiff} from '@libs/utils'
import {usePatchMutation} from '@queries'
import {FormObject} from '@components/forms/form.t'
import {CartItemsWithSuppliers, ListItem} from './cartValidation.t'

const {Text, Title} = Typography

export interface CartValidationProps extends AppContext {
  cart: Cart
  places: ListItem[]
  suppliers: ListItem[]
  validationItemsCategories: CartCategory[]
  cartItems: CartItemsWithSuppliers[]
}

const CartValidation: React.FC<CartValidationProps> = ({
  cart,
  places,
  suppliers,
  validationItemsCategories,
  modal,
  Panels,
  t,
  CRM,
  toast,
  ...props
}) => {
  const [step, setStep] = React.useState(0)

  const [cartItems, setCartItems] = useState<CartItemsWithSuppliers[]>(props.cartItems)

  const patchItemMutation = usePatchMutation()

  const cartDomain = useMemo<ErpDomain | undefined>(
    () => CRM?.getDomain('cart') as ErpDomain,
    [CRM]
  )

  const showSuccessModal = useCallback(() => {
    modal.confirm({
      title: (
        <Row justify='center'>
          <Title level={4}>{t('panel.cartValidation.validate.modal.title')}</Title>
        </Row>
      ),
      content: (
        <Col className='mx-3 mb-4'>
          <Row className='px-3 my-2'>
            <Text strong className='pre-line'>
              {t('panel.cartValidation.validate.modal.content')}
            </Text>
          </Row>
        </Col>
      ),
      icon: null,
      okText: t('panel.cartValidation.validate.modal.button'),
      onOk: () => {
        Panels.close(PanelsKeys.CART_VALIDATION)
      },
      cancelButtonProps: {style: {display: 'none'}},
      closable: false,
      maskClosable: false,
      width: 400,
      styles: {
        body: {
          padding: '2rem'
        }
      }
    })
  }, [Panels, modal, t])

  const onValidate = useCallback(async () => {
    if (!cartDomain) return

    const diff = saveDiff(
      cartDomain,
      {...(cart as unknown as FormObject)},
      {...(cart as unknown as FormObject), validated: true}
    )

    return patchItemMutation
      .mutateAsync({domain: cartDomain, id: cart._id, patchs: diff})
      .then(() => {
        showSuccessModal()
        window.SM?.sub.refresh('cart')
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.data?.err || 'panel.cartValidation.steps.suppliers.validate.error'
        toast.error(errorMessage)
      })
  }, [cart, cartDomain, patchItemMutation, showSuccessModal, toast])

  const nextStep = useCallback(() => {
    setStep(step + 1)
  }, [step])

  return (
    <Row>
      <Col span={22} offset={1}>
        <CartValidationHeader step={step} />
        {step === 0 && (
          <ProjectValidation cart={cart} places={places} suppliers={suppliers} onNext={nextStep} />
        )}
        {step === 1 && (
          <SuppliersValidation
            cart={cart}
            categories={validationItemsCategories}
            cartItems={cartItems}
            setCartItems={setCartItems}
            onNext={nextStep}
          />
        )}
        {step === 2 && (
          <CartFinancingPlan
            cart={cart}
            onSuccess={nextStep}
            saveLabel={t('panel.cartValidation.steps.financingPlan.button')}
          />
        )}
        {step === 3 && (
          <DocumentUpload
            projectId={typeof cart.project === 'string' ? cart.project : cart.project?._id}
            onSuccess={() => {
              onValidate()
            }}
          />
        )}
      </Col>
    </Row>
  )
}

export default withApp(CartValidation)
