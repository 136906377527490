import {Card, Space} from 'antd'
import React, {Dispatch, SetStateAction, useMemo} from 'react'
import SubCategory from './subcategory'
import {CartItemsWithSuppliers} from '../cartValidation.t'
import {Cart, CartCategory} from '@shared/interfaces'

interface CartCategoryCompoenentProps {
  cart: Cart
  category: CartCategory
  cartItems: CartItemsWithSuppliers[]
  setCartItems: Dispatch<SetStateAction<CartItemsWithSuppliers[]>>
  updatedCartItems: string[]
  setUpdatedCartItems: Dispatch<SetStateAction<string[]>>
}

const CartCategoryCompoenent: React.FC<CartCategoryCompoenentProps> = ({
  cart,
  category,
  cartItems,
  setCartItems,
  updatedCartItems,
  setUpdatedCartItems
}: CartCategoryCompoenentProps) => {
  const defaultBack = '#9B9B9B'
  const defautlFont = '#3E3E3F'

  const back = useMemo(() => (category.color ? category.color : defaultBack), [category])
  const font = useMemo(() => (category.fontColor ? category.fontColor : defautlFont), [category])

  return (
    <Card
      title={category.name}
      styles={{
        header: {
          color: font,
          fontSize: '1.1rem',
          background: back,
          paddingBottom: '5px'
        },
        body: {
          padding: '0px'
        }
      }}>
      <Space direction='vertical' className='w-100'>
        {category.subCategories.map((sub, index) => (
          <SubCategory
            key={index}
            cart={cart}
            subCategory={sub}
            cartItems={cartItems}
            setCartItems={setCartItems}
            backColor={back}
            fontColor={font}
            updatedCartItems={updatedCartItems}
            setUpdatedCartItems={setUpdatedCartItems}
          />
        ))}
      </Space>
    </Card>
  )
}

export default CartCategoryCompoenent
