import {useDomainByKey} from '@hooks/use-domain'
import {useInmemoriServices} from '@services'
import {Cart, SaleArticle, SupplierArticle} from '@shared/interfaces'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {invalidateDomainQueries} from './domains'
import {CatalogArticle, useCataLogContext} from '@store/catalog/context'

export function useAddToCartMutation() {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService
  const cartDomain = useDomainByKey('cart')

  const {cartItem: selectedCartItem} = useCataLogContext()

  return useMutation({
    mutationFn: ({
      allArticleIds,
      article,
      productId,
      cart
    }: {
      allArticleIds: string[]
      article: CatalogArticle
      productId: string
      cart: Cart
    }) => {
      const {saleArticle, supplierArticle, price, searchContext} = article

      const cartItem =
        selectedCartItem ||
        cart?.cartItems.find(
          (it) =>
            it?.searchContext?.product === productId ||
            allArticleIds.includes(
              (it.saleArticle as SaleArticle)._id || (it.saleArticle as string)
            )
        )

      return cartItem
        ? erpApi.replaceCartItemArticle({
            saleArticle: (saleArticle as SaleArticle)?._id,
            supplierArticle: (supplierArticle as SupplierArticle)?._id,
            price,
            searchContext,
            isFromCatalog: true,
            cartItem
          }) // Added from catalog
        : erpApi.addToCart(
            [
              {
                saleArticle: (saleArticle as SaleArticle)?._id,
                supplierArticle: (supplierArticle as SupplierArticle)?._id,
                price,
                isFromCatalog: true,
                searchContext
              }
            ],
            cart._id
          )
    },
    onSuccess: (response, {cart}) =>
      cartDomain && invalidateDomainQueries(queryClient, 'carts', cart._id)
  })
}
