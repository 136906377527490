import {PlusCircleOutlined} from '@ant-design/icons'
import Exec from '@libs/exec'
import {Action, Cart, ErpDomain} from '@shared/interfaces'
import {AppContext} from '@store/app'
import {Button, Col, Row, Space, Statistic, Typography} from 'antd'
import React, {useContext, useMemo} from 'react'
import {useApp} from '@store/app'
import {formatCurrency} from '@libs/utils'

const {Text} = Typography

interface CartHeaderProps {
  cart: Cart
}

const CartHeader: React.FC<CartHeaderProps> = ({cart}: CartHeaderProps) => {
  const prices = cart.pricesString.tvas.summary

  const app = useContext(AppContext)

  const {t} = useApp()

  const triggerAction = async (action: Action) => {
    try {
      await Exec(action.code, {app: app, action})
    } catch (e) {
      console.log(e)
    }
  }

  const actions = useMemo(() => {
    const items = []

    const cartDomain = app.CRM?.getDomain('cart') as ErpDomain
    const productFinderAction = cartDomain.actions.find((a) => a.key === 'productFinder')

    if (
      productFinderAction &&
      (app.ability?.can(`action.${productFinderAction._id}`, 'cart') ||
        app.ability?.can('action.all', 'cart'))
    ) {
      items.push(
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            triggerAction(productFinderAction)
          }}>
          {t('cart.header.addProduct')}
        </Button>
      )
    }

    return items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.CRM, app.ability])

  return (
    <Row className='w-100' justify='space-between' align='bottom'>
      {!cart.locked && !cart.isAbandoned && !!actions.length && <Space>{...actions}</Space>}
      {!!prices && (
        <Col className='ms-auto d-flex flex-column align-items-end'>
          <Statistic
            className='ms-auto'
            prefix={<Text type='secondary'>Total TTC</Text>}
            value={prices.ttc}
            valueStyle={{color: '#935A55'}}
          />
          {cart.creditInvoices.length > 0 && (
            <Statistic
              className='ms-auto'
              prefix={<Text type='secondary'>Avant avoir</Text>}
              value={formatCurrency(cart.originalAmount.prices.ttc)}
              valueStyle={{fontSize: '1.1em'}}
            />
          )}
          {cart.hasDeposit && (
            <Statistic
              className='ms-auto'
              prefix={<Text type='secondary'>Acompte</Text>}
              value={formatCurrency(cart.deposit)}
              valueStyle={{fontSize: '1.1em'}}
            />
          )}
        </Col>
      )}
    </Row>
  )
}

export default CartHeader
