import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {LookupItem, LookupOptions} from './erp-api'
import {IntRange} from 'rc-picker/lib/interface'
import {FormObject} from '@components/forms/form.t'

/* -------------------------------------------------------------------------- */
/*                              Domain & Entities                             */
/* -------------------------------------------------------------------------- */

/** Domain keys */
export type DomainKey =
  | 'project'
  | 'task'
  | 'service'
  | 'cart'
  | 'document'
  | 'note'
  | 'workflow'
  | 'saleArticle'
  | 'supplierArticle'
  | 'cartItem' // not exhaustive, just to avoid type collision with DomainKeyPlural

export type DomainKeyPlural =
  | 'articles'
  | 'projects'
  | 'tasks'
  | 'services'
  | 'carts'
  | 'forms'
  | 'documents'
  | 'notes'
  | 'workflows'
  | 'cartItems' // not exhaustive, just to avoid type collision with DomainKey

/** Base entity interface */
export interface BaseEntity {
  _id: string
  uat: Date
  cat: Date
  createdBy: string
  updatedBy: string
  deletedBy?: string
  ref: string
  counter: number
  displayValues: Array<string>
  deleted: boolean
  name?: string
  isHydrated?: boolean
  __type: string
}

/** Domain interface */
export interface Domain extends BaseEntity {
  icon?: IconProp
  name: string
  isHydrated?: boolean
  displayValues: Array<string>
}

/** Domain relation interface */
export interface DomainRelation {
  field: string
  hydrate: boolean
  isChild?: boolean
  isParent?: boolean
  key: DomainKey
  keyPlural: DomainKeyPlural
  multi?: string
  name: string
  namePlural: string
  sub: boolean
  withDeleted?: boolean
}

/* -------------------------------------------------------------------------- */
/*                              ERP Domain                                  */
/* -------------------------------------------------------------------------- */

/** ERP Domain Options */
export interface ErpDomainOptions {
  duplicate: {enabled?: boolean}
}

/** Domain save method enum */
export enum DomainSaveMethod {
  PUT = 'put',
  PATCH = 'patch'
}

/** ERP Domain interface */
export interface ErpDomain extends Domain {
  actions: Action[]
  forms: {
    create?: string
    read?: string
    filter?: string
  }
  isChild?: boolean
  isParent?: boolean
  key: DomainKey
  namePlural: string
  keyPlural: DomainKeyPlural
  sub?: boolean
  hideAsSub?: boolean
  tabOrder?: string[]
  tabs: Tab[]
  fields: Record<string, unknown>
  relations: DomainRelation[]
  lookupOptions: LookupOptions
  options: ErpDomainOptions
  saveMethod?: DomainSaveMethod
  mVirtuals: Array<{
    key: string
  }>
}

/* -------------------------------------------------------------------------- */
/*                             Tabs & Actions                               */
/* -------------------------------------------------------------------------- */

/** Tab component types */
export enum TabComponentType {
  SINGLE_FORM = 'singleForm',
  LIST_AND_FORM = 'listAndForm'
}

/** Tab CRUD info interface */
export interface TabCRUDInfo {
  /** The function to execute as a string */
  fn: string
  /** ID of the form */
  form: string
}

/** Tab interface */
export interface Tab extends BaseEntity {
  _id: string
  key: DomainKey
  name: string
  component: TabComponentType
  crud: {
    create: TabCRUDInfo
    read: TabCRUDInfo
    update: TabCRUDInfo
    delete: TabCRUDInfo
  }
  actions: Action[]
  isHidden?: string
}

/** Action location enum */
export enum ActionLocation {
  BAR = 'bar',
  MENU = 'menu',
  DROPDOWN = 'dropdown'
}

/** Action nature enum */
export enum ActionNature {
  DOC = 'doc',
  DOMAIN = 'domain'
}

/** Action type enum */
export enum ActionType {
  CUSTOM = 'custom',
  ENDPOINT = 'endpoint',
  CUSTOM_ENDPOINT = 'customEndpoint'
}

/** Action interface */
export interface Action extends Domain {
  code: string
  hidden: string
  endpoint: Endpoint
  endpoints: Endpoint[]
  location: ActionLocation
  nature: ActionNature
  type: ActionType
  key: string
  order?: number
  tableLocation: 'row' | 'header' | 'multiSelection'
  hideOnMultiselection: boolean
}

/* -------------------------------------------------------------------------- */
/*                             Form Definitions                             */
/* -------------------------------------------------------------------------- */

/** Form layout component interface */
export interface FormLayoutComponent {
  id: string
  label?: string
}

/** Form layout column interface */
export interface FormLayoutColumn {
  id: string
  label?: string
  showLabel?: boolean
  weight: number
  hidden?: string
  components: FormLayoutComponent[]
  className?: string
}

/** Form layout row interface */
export interface FormLayoutRow {
  id: string
  label?: string
  showLabel?: boolean
  hidden?: string
  columns: FormLayoutColumn[]
  className?: string
}

/** Form layout interface */
export interface FormLayout {
  name: string
  rows: FormLayoutRow[]
}

/** Form Domain interface */
export interface FormDomain extends Domain {
  key: string
  type: string
  layout?: FormLayout
  outputMw: string
  fields: Array<FieldDomain>
  __type: 'form'
}

/** Field nature map */
export interface FieldNatureMap {
  input: 'input'
  widget: 'widget'
}

/** DateTime input types */
export enum DateTimeInputType {
  date = 'date',
  datetime = 'datetime',
  'date-range' = 'date-range',
  'datetime-range' = 'datetime-range',
  time = 'time',
  'time-range' = 'time-range'
}

/** Other field input types */
export enum OtherFieldInputType {
  array = 'array',
  'array-object' = 'array-object',
  bool = 'bool',
  code = 'code',
  color = 'color',
  email = 'email',
  entity = 'entity',
  file = 'file',
  json = 'json',
  layout = 'layout',
  location = 'location',
  multiselect = 'multiselect',
  number = 'number',
  object = 'object',
  password = 'password',
  phone = 'phone',
  'price-bundle' = 'price-bundle',
  rich = 'rich',
  'rich-inline' = 'rich-inline',
  select = 'select',
  text = 'text',
  textarea = 'textarea',
  textareainfinity = 'textareainfinity',
  url = 'url',
  icon = 'icon',
  'file-upload' = 'file-upload',
  'radio-bool' = 'radio-bool',
  wokflow = 'workflow',
  table = 'table'
}

/** Field input type union */
export type FieldInputType = DateTimeInputType | OtherFieldInputType

/** Field input subtypes */
export interface FieldInputSubType {
  text: 'text'
  number: 'number'
  email: 'email'
  url: 'url'
  phone: 'phone'
  entity: 'entity'
  location: 'location'
  file: 'file'
}

/** Field widget type map */
export interface FieldWidgetTypeMap {
  button: 'button'
  cart: 'cart'
  'html-view': 'html-view'
}

export type FieldNature = FieldNatureMap[keyof FieldNatureMap]
export type FieldWidgetType = FieldWidgetTypeMap[keyof FieldWidgetTypeMap]
export type FieldSubType = FieldInputSubType[keyof FieldInputSubType]

/** Field Domain interface */
export interface FieldDomain extends BaseEntity {
  key: string
  name: string
  nature: FieldNature
  nullState?: boolean
  type: FieldInputType
  subtype?: FieldSubType
  widgetType: FieldWidgetType
  label: string
  hidden: string | boolean
  form: FormDomain | string
  step?: number
  /**
   * Will set hour interval for time-input and date-input.
   */
  hourStep?: IntRange<1, 23> | undefined
  /**
   * Will set minute interval for time-input and date-input.
   */
  minuteStep?: IntRange<1, 59> | undefined
  min?: number
  max?: number
  placeholder?: string
  options?: string[]
  subform?: string
  action?: string
  onChange?: string
  disabled?: boolean
  required?: boolean
  defaultValue?: string
  widgetContext?: string
  flat?: boolean
  prefix?: string
  radio?: boolean
  pattern?: string
  subType?: string
  /**
   * Domain: a Handlebars template to be parsed by Handlebars.
   * Used for entity picker.
   */
  domain?: string
  /**
   * Query: a Handlebars template to be parsed first, then to JSON.
   * Used to filter fetched data on the server side.
   */
  q?: string
  /**
   * MatchLookup: a Handlebars template for the lookup stage in an aggregate query.
   */
  matchLookup?: string
  /**
   * QueryParams: a Handlebars template to pass custom query params to lookup.
   */
  queryParams?: string
  /**
   * Sort: a Handlebars template used to sort data fetched from the server.
   */
  sort?: string
  createContext?: string
  hideLabel?: boolean
  limit?: number
  hideCTAs?: boolean
  __type: 'field'
  table?: string
  tooltip?: string
  apiContext?: string
  /**
   * Related to price-bundle-input configuration.
   */
  isTvaPath?: boolean
  tvaPath?: string
}

/** Basic domain interface */
export interface BasicDomain {
  _id: string
}

/** Option data interface */
export interface OptionData {
  key: string
  value: string
}

export interface Select extends BasicDomain {
  _id: string
  options: OptionData[]
}

export interface Snippet extends BasicDomain {
  code: string
  fnName: string
  name: string
  subtype: string
  type: string
}

export interface Permission {
  fields: string[]
}

/* -------------------------------------------------------------------------- */
/*                          User & Project Entities                         */
/* -------------------------------------------------------------------------- */

/** Profile interface */
export interface Profile extends BaseEntity {
  _id: string
  firstname: string
  lastname: string
  houses: string[]
  initials?: string
  corporate?: boolean
  companyType?: string
  __type: 'profile'
}

/** Project power interface */
export interface ProjectPower {
  profile?: Profile
}

/** Project interface */
export interface Project extends BaseEntity {
  __type: 'project'
  defunct?: Profile
  power: ProjectPower
}

/* -------------------------------------------------------------------------- */
/*                           HTTP & Endpoints                               */
/* -------------------------------------------------------------------------- */

/** HTTP method enum */
export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

/** Endpoint interface */
export interface Endpoint extends Domain {
  path: string
  method: HttpMethod
  code: string
}

/* -------------------------------------------------------------------------- */
/*                          Product & Media Entities                        */
/* -------------------------------------------------------------------------- */

/** House interface */
export interface House extends BaseEntity {
  __type: 'house'
  name: string
}

/** Product interface */
export interface Product extends Domain {
  requiredPriceInformation: boolean
  isCatalog?: boolean
  options?: string[]
  pics: Images[]
  description: string
  __type: 'product'
}

/** Images interface */
export interface Images {
  hash: string
  _id: string
}

/** Article interface */
export interface Article extends BaseEntity {
  pics: Images[]
  price: {
    ttc: number | string
  }
  product: string | Product
  description?: string
  desc?: string
  tags: string[]
  house?: string | House
  __type: 'article'
  priceInformation?: string
}

/* -------------------------------------------------------------------------- */
/*                              Credit Invoices                             */
/* -------------------------------------------------------------------------- */

/** Credit Invoice Item interface */
export interface CreditInvoiceItem {
  creditInvoice: CreditInvoice | string
  amount: number
  qty: number
}

/** Credit Invoice interface */
export interface CreditInvoice extends BaseEntity {
  cart: Cart
  document: string
  motive: string
  motiveLabel: string
  note: string
  total: number
  isPartial: {type: boolean; default: false}
  accountingName: string
  __type: 'creditInvoice'
}

export interface CreditInvoiceCreate {
  motive: string
  note?: string
  credits: CreditInvoiceItem[]
}

/* -------------------------------------------------------------------------- */
/*                              Cart Item Entities                          */
/* -------------------------------------------------------------------------- */

/** Cart Item Search Context interface */
export interface CartItemSearchContext extends FormObject {
  product: Product | string
  service: Service | string
}

export interface CartItemOriginalAmount {
  total: PriceBundle
}

/** Cart Item Status enum */
export enum CartItemStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  PENDING_SALE = 'pendingSaleArticle',
  PENDING_SUPPLIER = 'pendingSupplierArticle',
  MISSING = 'missing'
}

/** Cart Item interface */
export interface CartItem extends BaseEntity {
  article: string
  buyingPrice?: PriceBundle
  description: string
  estimate?: boolean
  internalDescription: string
  isPart: boolean
  name: string
  price: PriceBundle
  priceInformation: string
  product: Product
  qty: number
  regulatoryDescription: string
  requiredPriceInformation: boolean
  saleArticle: string | SaleArticle
  searchContext?: CartItemSearchContext
  status: CartItemStatus
  supplierArticle?: string | SupplierArticle
  supplierRef: string
  total: PriceBundle
  credits: CreditInvoiceItem[]
  originalAmount: CartItemOriginalAmount
  __type: 'cartItem'
}

/* -------------------------------------------------------------------------- */
/*                                 Cart Entities                            */
/* -------------------------------------------------------------------------- */

/** Cart Prices interface */
export interface CartPrices {
  tvas: {
    [key: string]: {
      ht: number
      ttc: number
      tva: number
    }
    summary: {
      ht: number
      ttc: number
      tva: number
    }
  }
  payments: {
    ordinary: number
    optional: number
    advance: number
  }
}

/** Cart Sub Category interface */
export interface CartSubCategory extends BasicDomain {
  name: string
  articles: CartItem[]
}

/** Cart Category interface */
export interface CartCategory extends BasicDomain {
  name: string
  color: string
  fontColor: string
  subCategories: CartSubCategory[]
}

/** Cart Categories interface */
export interface CartCategories {
  internal: CartCategory[]
  regulatory: CartCategory[]
  hasMarbleWork: boolean
}

/** Cart Status enum */
export enum CartStatus {
  ESTIMATE = 'estimate',
  ORDER = 'order',
  INVOICE = 'invoice'
}

export interface CartOriginalAmount {
  buyingPrices: PriceBundle
  prices: PriceBundle
}

/** Cart interface */
export interface Cart extends Domain {
  hasDeposit: boolean
  deposit: number
  status: CartStatus
  cartItems: CartItem[]
  project: Project | string
  prices: CartPrices
  locked: boolean
  isAbandoned: boolean
  categories: CartCategories
  pricesString: CartPrices
  creditInvoices: CreditInvoice[]
  originalAmount: CartOriginalAmount
  __type: 'cart'
}

/* -------------------------------------------------------------------------- */
/*                                Options Entities                          */
/* -------------------------------------------------------------------------- */

/** Option Value interface */
export interface OptionValue extends Domain {
  option?: string
  value?: string
  __type: 'optionValue'
}

/** Option interface */
export interface Option extends Domain {
  optionValues: OptionValue[]
  __type: 'option'
}

/* -------------------------------------------------------------------------- */
/*                          Service & Place Entities                        */
/* -------------------------------------------------------------------------- */

/** Service Location interface */
interface ServiceLocation extends FormObject {
  place?: Place
}

/** Service interface */
export interface Service extends Domain {
  arrival: ServiceLocation
  departure: ServiceLocation
  location: ServiceLocation
  stop: ServiceLocation
  __type: 'service'
}

/** Place interface */
export interface Place extends Domain {
  name: string
  dummy: boolean
  __type: 'place'
}

/* -------------------------------------------------------------------------- */
/*                              Search Interfaces                           */
/* -------------------------------------------------------------------------- */

/** Search Sale Articles interface */
export interface SearchSaleArticles {
  product?: string | Product
  service?: Service
  options?: Array<OptionValue>
  cart: Cart
  action?: Action
}

/** Search Product Articles Result interface */
export interface SearchProductArticlesResult {
  placeArticles?: Array<Article>
  sectorArticles?: Array<Article>
  productArticles?: Array<Article>
  places?: Array<Place>
}

/* -------------------------------------------------------------------------- */
/*                             Category Entities                            */
/* -------------------------------------------------------------------------- */

/** Category interface */
export interface Category extends BaseEntity {
  name: string
  color?: string
  fontColor?: string
  type: 'regulatory' | 'internal' | 'task'
  parent?: string | Category
}

/* -------------------------------------------------------------------------- */
/*                            Supplier Entities                             */
/* -------------------------------------------------------------------------- */

/** Supplier interface */
export interface Supplier extends BaseEntity {
  name: string
  houses: string[]
  transportPriceParams: {
    kmCosts: number
    transportPackage: number
    appliedIncreaseRate: number
    increase: number
    serviceCosts: number
    supportCosts: number
    abroadSupportCosts: number
  }
  __type: 'supplier'
}

/* -------------------------------------------------------------------------- */
/*                       Documents & Team Entities                          */
/* -------------------------------------------------------------------------- */

/** ERP Document interface */
export interface ErpDocument extends BaseEntity {
  name: string
  __type: 'document'
}

/** Team interface */
export interface Team extends BaseEntity {
  name: string
  color: string
  __type: 'document'
}

/* -------------------------------------------------------------------------- */
/*                             Task Entities                                */
/* -------------------------------------------------------------------------- */

/** Task Owner interface */
export interface TaskOwner {
  team?: Team
  profile?: Profile
}

/** Task Status interface */
export interface TaskStatus {
  done: {
    checked: boolean
    at: Date
    by: Profile
  }
  disabled: {
    checked: boolean
    at: Date
    by: Profile
  }
  obsolete: {
    checked: boolean
    at: {
      _id: string
      firstname: string
      lastname: string
    }
  }
}

/** Task interface */
export interface Task extends BaseEntity {
  name: string
  comment?: string
  deadline?: string
  status: TaskStatus
  owners?: TaskOwner[]
  project?: string
  __type: 'task'
}

/* -------------------------------------------------------------------------- */
/*                             Table Entities                               */
/* -------------------------------------------------------------------------- */

/** Table Column interface */
export interface TableColumn {
  dataIndex: string
  key: string
  title: string
  render: string
  width?: string
  className?: string
}

/** ERP Table interface */
export interface ErpTable extends BaseEntity {
  name: string
  type: string
  key: string
  pagination: boolean
  endpoint: Endpoint
  actions: Action[]
  domain: ErpDomain
  resolvedColumns: TableColumn[]
  extendable: boolean
  static: boolean
  nested: boolean
  isRowExpandable?: string
  nestedTable?: string | ErpTable
  fetchExpandedData?: string
  forms?: {
    create: string
    edit: string
  }
  __type: 'table'
}

/* -------------------------------------------------------------------------- */
/*                     Sale & Supplier Article Entities                     */
/* -------------------------------------------------------------------------- */

/** Sale Article interface */
export interface SaleArticle extends BaseEntity {
  __type: 'saleArticle'
  tags: string[]
  pics: Images[]
  product: string | Product
  price: {
    ttc: number | string
    estimate: boolean
  }
  priceInformation?: string
}

/** Supplier Article interface */
export interface SupplierArticle extends BaseEntity {
  __type: 'supplierArticle'
  price: {
    ttc: number | string
    estimate: boolean
  }
  place?: Place
  supplier?: Supplier
  internalDescription: string
}

/* -------------------------------------------------------------------------- */
/*                              Pricing Entities                            */
/* -------------------------------------------------------------------------- */

/** Price interface */
export interface Price extends Omit<FormObject, '_id'> {
  ttc: number
  ht: number
  tva: number
}

/** Price Bundle interface */
export interface PriceBundle extends Price {
  string: {
    [K in keyof Price]: string
  }
  estimate?: boolean
}

/* -------------------------------------------------------------------------- */
/*                          Search Articles Entities                        */
/* -------------------------------------------------------------------------- */

/** Search Articles Item interface */
export interface SearchArticlesItem extends LookupItem {
  saleArticle: SaleArticle
  cart: string
  description: string
  displayValues: string[]
  price: PriceBundle
  regulatoryDescription: string
  searchContext: CartItemSearchContext
  status: string
  supplierArticle: BaseEntity
}

/** Search Articles interface */
export interface SearchArticles {
  cart: Cart
  service: Service
  articles: SearchArticlesItem[]
}

/* -------------------------------------------------------------------------- */
/*                             Error Scopes                                 */
/* -------------------------------------------------------------------------- */

/** Datadgo Error Scope enum */
export enum DatadgoErrorScope {
  GLOBAL = 'global',
  MARKETPLACE = 'marketplace'
}

/* -------------------------------------------------------------------------- */
/*                             Upload Entities                              */
/* -------------------------------------------------------------------------- */

/** Upload interface */
export interface Upload extends BaseEntity {
  name: string
  type: string
  file: {
    path: string
    url: string
    cat: Date
    type: string
    readableType: string
  }
  __type: 'upload'
}

/* -------------------------------------------------------------------------- */
/*                         Project Payments Entities                          */
/* -------------------------------------------------------------------------- */

export interface ProjectPayerInvoice extends BaseEntity {
  cart: Cart
  amount: number
}
export interface ProjectPayers extends BaseEntity {
  profile: Profile
  amount: number
  type: string
  invoices: ProjectPayerInvoice[]
}

export interface PaymentsPayload {
  payers: ProjectPayers[]
}

/* -------------------------------------------------------------------------- */
/*                         Recovery Events Entities                           */
/* -------------------------------------------------------------------------- */

export enum RecoveryEventType {
  PAUSE = 'pause',
  RESUME = 'resume',
  COMMUNICATION = 'communication',
  BALANCED = 'balanced',
  INVOICED = 'invoiced',
  DEBTS_COLLECTION = 'debtsCollection'
}

export enum RecoveryEventStatus {
  PENDING = 'pending',
  SUSPENDED = 'suspended',
  DONE = 'done',
  ERROR = 'error'
}

/** Recovery Events interface */
export interface RecoveryEvent extends BaseEntity {
  name: string
  communicationId?: string
  date: Date
  project: string | Project
  recipient?: string
  status: RecoveryEventStatus
  template?: string
  type: RecoveryEventType
  __type: 'recoverEvent'
}
