import {AppContext, withApp} from '@store/app'
import {Col, Row, Typography} from 'antd'
import React from 'react'
import CartFinancingPlan from '@components/cart-financing-plan'
import {Cart} from '@shared/interfaces'

const {Title} = Typography

export interface CartFinancingPlanPanelProps extends AppContext {
  cart: Cart
  onSuccess: () => void
}

const CartFinancingPlanPanel: React.FC<CartFinancingPlanPanelProps> = ({cart, onSuccess, t}) => {
  return (
    <Col>
      <Title level={4}>{t('panel.cartFinancingPlan.title')}</Title>

      <Row className='w-100'>
        <CartFinancingPlan cart={cart} onSuccess={onSuccess} />
      </Row>
    </Col>
  )
}

export default withApp(CartFinancingPlanPanel)
