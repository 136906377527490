import {AppContext, withApp} from '@store/app'
import {Button, Table, theme, Typography} from 'antd'
import React, {useMemo} from 'react'
import {Cart, CartItem, CreditInvoice, CreditInvoiceItem} from '@shared/interfaces'
import {RightOutlined} from '@ant-design/icons'
import {ColumnsType} from 'antd/lib/table'
import Link from 'next/link'
import {formatCurrency} from '@libs/utils'

const {useToken} = theme
const {Text} = Typography

interface CartArticleCreditsProps extends AppContext {
  cart: Cart
  cartItem: CartItem
}

const CartArticleCredits: React.FC<CartArticleCreditsProps> = ({
  cart,
  cartItem,
  t
}: CartArticleCreditsProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const columns = useMemo(() => {
    const defaultColumns: ColumnsType<CreditInvoiceItem> = [
      {
        title: (
          <RightOutlined
            className='ms-2'
            style={{transform: !isOpen ? 'none' : 'rotate(90deg)', transition: '0.2s'}}
            onClick={() => setIsOpen(!isOpen)}
          />
        ),
        key: 'caret',
        dataIndex: 'caret',
        width: 50
      },
      {
        title: (
          <Text>
            {isOpen
              ? t('cart-widget.article.invoiceCredit.table.article')
              : `${t('cart-widget.article.invoiceCredit.table.article')} : ${cartItem.credits.length}`}
          </Text>
        ),
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          const creditInvoice = record.creditInvoice as CreditInvoice
          return (
            <Text>
              {creditInvoice?.motiveLabel}
              <Button type='link' size='small' style={{padding: 0, paddingInlineStart: '2px'}}>
                <Link
                  target='_blank'
                  className='text-decoration-underline'
                  href={`/domain/projects/${cart.project}/documents/${creditInvoice.document}`}>
                  ({creditInvoice?.accountingName})
                </Link>
              </Button>
            </Text>
          )
        }
      }
    ]

    if (isOpen) {
      defaultColumns.push({
        title: <Text type='secondary'>{t('cart-widget.article.invoiceCredit.table.qty')} </Text>,
        width: 95,
        dataIndex: 'qty',
        className: 'text-end',
        key: 'qty',
        render: (text, record) => {
          return <Text>{record.qty || '0'}</Text>
        }
      })
      defaultColumns.push({
        title: <Text type='secondary'>{t('cart-widget.article.invoiceCredit.table.amount')}</Text>,
        dataIndex: 'amount',
        width: 140,
        className: 'text-end',
        key: 'amount',
        render: (text, record) => {
          return <Text>{formatCurrency(record.amount)}</Text>
        }
      })
    }
    return defaultColumns
  }, [cart.project, cartItem.credits.length, isOpen, t])

  const items = useMemo(() => {
    if (!isOpen) return []

    return cartItem.credits
  }, [cartItem.credits, isOpen])

  if (!cartItem.credits || cartItem.credits.length === 0) return null

  return (
    <>
      <style>
        {`
        .ant-table-placeholder {
          display: none;
        }
      `}
      </style>

      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        rowKey='key'
        locale={{emptyText: ''}}
        rowHoverable={false}
      />
    </>
  )
}

export default withApp(CartArticleCredits)
