import React from 'react'
import {ConfigProvider} from 'antd'
import frFR from 'antd/locale/fr_FR'
import Sidebar from '@components/catalog/sidebar'
import Content from '@components/catalog/content'
import CatalogProvider from '@store/catalog/provider'
import {Cart, CartItem} from '@shared/interfaces'
import antdConfig from './antd-config'

interface CatalogProps {
  hasProject?: boolean
  onClose?: () => void
  product?: string
  cart?: Cart
  cartItem?: CartItem
}

const Catalog: React.FC<CatalogProps> = ({onClose, product, cart, cartItem}) => {
  return (
    <ConfigProvider theme={antdConfig} locale={frFR}>
      <CatalogProvider onClose={onClose} product={product} cart={cart} cartItem={cartItem}>
        <div
          className='catalog-panel fullscreen d-flex flex-column p-0 overflow-auto'
          style={{backgroundColor: '#e6bfb1'}}>
          <div className='catalog-sidebar-widget d-flex justify-content-center flex-column flex-lg-row position-relative m-auto'>
            <Sidebar />
            <Content />
          </div>
        </div>
      </CatalogProvider>
    </ConfigProvider>
  )
}

export default Catalog
