import {useInmemoriServices} from '@services'
import {useQuery} from '@tanstack/react-query'
import {erpKeys} from './erp'

export function useGetPaymentsQuery(projectId?: string, withOrders?: boolean) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: projectId ? erpKeys.projects._ctx.payments(projectId).queryKey : [],
    queryFn: () =>
      erpApi.getPayments(projectId, withOrders).then(({data}) => {
        return data.data
      }),
    gcTime: 0,
    enabled: Boolean(projectId),
    staleTime: 0,
    refetchOnWindowFocus: false
  })
}
