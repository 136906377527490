/* eslint-disable @typescript-eslint/no-explicit-any */
import {AppContext, withApp} from '@store/app'
import {Button, Checkbox, Col, Row, Space, Statistic, Typography, Table, InputNumber} from 'antd'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Cart, CartItem, CreditInvoiceCreate, CreditInvoiceItem} from '@shared/interfaces'
import {useGetByIdQuery} from '@queries'
import {formatCurrency, money} from '@libs/utils'
import {CloseOutlined} from '@ant-design/icons'
import {PanelsKeys} from '@libs/panels/panels.t'
import {useMutation} from '@tanstack/react-query'
import {useInmemoriServices} from '@services'
import FormComponent from '@components/forms/form'
import {useCallbackRef} from 'use-callback-ref'
import {FormikProps} from 'formik'
import {FormObject, FormState} from '@components/forms/form.t'

const {Title} = Typography

export interface CartCreditPanelProps extends AppContext {
  cart: Cart
  onSuccess: () => void
}

const CartCreditPanel: React.FC<CartCreditPanelProps> = ({
  cart: cartProps,
  onSuccess,
  t,
  Panels
}) => {
  // ––– form state –––
  const formRef = useRef<FormikProps<FormObject>>()
  const [formState, setFormState] = useState<FormState>({
    isDirty: false,
    isValid: false,
    isSubmitting: false
  })
  const formikRef = useCallbackRef<FormikProps<FormObject>>(null, (node) => {
    if (node && formRef) formRef.current = node
    if (
      node &&
      (node.dirty !== formState.isDirty ||
        node.isValid !== formState.isValid ||
        node.isSubmitting !== formState.isSubmitting)
    ) {
      setFormState({
        isDirty: node.dirty,
        isValid: node.isValid,
        isSubmitting: node.isSubmitting
      })
    }
  })

  // ––– retrieve cart –––
  const {data: cart, isLoading: cartLoading} = useGetByIdQuery<Cart>(
    'carts',
    cartProps?._id || '',
    {
      query: {enabled: Boolean(cartProps?._id), gcTime: 0, staleTime: 0},
      api: {hydrate: true}
    }
  )

  // ––– state for row values and selection –––
  // We preserve each row’s state with a "selected" flag.
  // If no state exists for a given row, it will be assumed selected by default.
  const [rowStates, setRowStates] = useState<
    Record<string, {selected: boolean; qty: number; amount: number}>
  >({})

  const erpApi = useInmemoriServices().erpApiService

  const useCreateCreditMutation = useMutation({
    mutationFn: async (credits: Partial<CreditInvoiceItem>[]) =>
      cart &&
      erpApi.createCartCredit(cart._id, {
        ...formRef.current?.values,
        credits
      } as unknown as CreditInvoiceCreate),
    onSuccess: () => {
      window.app.toast.success()
      window.SM?.sub.refresh('cart')
      window.SM?.sub.refresh('document')
      Panels.close(PanelsKeys.CART_CREDIT)
      onSuccess()
    }
  })

  // ––– get cart items (ignoring “part” items) –––
  const cartItems = useMemo<CartItem[]>(
    () => cart?.cartItems.filter((item) => !item.isPart && item.total.ttc > 0) || [],
    [cart]
  )

  const selectedItems = useMemo(() => {
    return Object.entries(rowStates)
      .filter(([_, value]) => value?.selected ?? true)
      .map(([cartItem, values]) => ({
        cartItem,
        ...(values.qty > 0 && {qty: values.qty}),
        ...(values.qty === 0 && {amount: values.amount})
      }))
  }, [rowStates])

  // Toggle the selection for a row while preserving its current values.
  const toggleSelection = useCallback((item: CartItem) => {
    setRowStates((prev) => {
      const currentSelected = prev[item._id]?.selected ?? true
      return {
        ...prev,
        [item._id]: {
          selected: !currentSelected,
          qty: prev[item._id]?.qty ?? item.qty,
          amount: prev[item._id]?.amount ?? item.total.ttc
        }
      }
    })
  }, [])

  // “Select all” toggles the selection flag on all rows.
  const selectAll = useCallback(() => {
    setRowStates((prev) => {
      const allSelected =
        cartItems.length > 0 && cartItems.every((item) => prev[item._id]?.selected)
      const newStates = {...prev}
      cartItems.forEach((item) => {
        newStates[item._id] = {
          selected: !allSelected,
          qty: newStates[item._id]?.qty ?? item.qty,
          amount: newStates[item._id]?.amount ?? item.total.ttc
        }
      })
      return newStates
    })
  }, [cartItems])

  const handleQtyChange = useCallback((item: CartItem, newQty: number | null) => {
    if (newQty === null) return
    setRowStates((prev) => {
      const newAmount =
        newQty === item.qty
          ? item.price.ttc
          : money(item.price.ttc / item.qty).multiply(newQty).value

      const record = prev[item._id]
      if (record) {
        return {
          ...prev,
          [item._id]: {...record, qty: newQty, amount: newAmount}
        }
      }
      return {
        ...prev,
        [item._id]: {selected: true, qty: newQty, amount: newAmount}
      }
    })
  }, [])

  const handlePriceChange = useCallback((item: CartItem, newPrice: number | null) => {
    if (newPrice === null) return
    setRowStates((prev) => {
      const record = prev[item._id]
      if (record) {
        return {
          ...prev,
          [item._id]: {...record, qty: 0, amount: newPrice}
        }
      }
      return {
        ...prev,
        [item._id]: {selected: true, qty: 0, amount: newPrice}
      }
    })
  }, [])

  const totalCredit = useMemo(() => {
    return cartItems.reduce((acc, item) => {
      const state = rowStates[item._id]
      // If no state exists, assume it is selected.
      if (state?.selected ?? true) {
        return acc.add(state ? state.amount : item.price.ttc)
      }
      return acc
    }, money(0)).value
  }, [cartItems, rowStates])

  const tableData = useMemo(() => {
    return cartItems.map((item) => {
      const state = rowStates[item._id]
      return {
        key: item._id,
        item,
        selected: state ? state.selected : true,
        qty: state ? state.qty : item.qty,
        amount: state ? state.amount : item.total.ttc
      }
    })
  }, [cartItems, rowStates])

  const allSelected = useMemo(() => {
    return cartItems.length > 0 && cartItems.every((item) => rowStates[item._id]?.selected ?? true)
  }, [cartItems, rowStates])

  useEffect(() => {
    if (cartItems.length > 0) {
      selectAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems])

  const columns = [
    {
      title: <Checkbox checked={allSelected} onChange={selectAll} />,
      dataIndex: 'selected',
      key: 'selected',
      width: 50,
      render: (_: any, record: any) => (
        <Checkbox checked={record.selected} onChange={() => toggleSelection(record.item)} />
      )
    },
    {
      title: t('panel.cartCredit.table.article'),
      dataIndex: 'item',
      key: 'item',
      render: (item: CartItem) => (
        <>
          <div>{item.name}</div>
          <div style={{fontSize: '0.85em', color: '#888'}}>
            {item.internalDescription || item.description || ''}
          </div>
        </>
      )
    },
    {
      title: t('panel.cartCredit.table.qty'),
      dataIndex: 'qty',
      key: 'qty',
      width: 95,
      render: (_: any, record: any) =>
        record.selected ? (
          <InputNumber
            min={0}
            max={record.item.qty}
            value={record.qty}
            onChange={(value) => handleQtyChange(record.item, value)}
          />
        ) : (
          <InputNumber min={0} max={record.item.qty} value={record.qty} disabled />
        )
    },
    {
      title: t('panel.cartCredit.table.price'),
      dataIndex: 'amount',
      key: 'amount',
      width: 125,
      render: (_: any, record: any) =>
        record.selected ? (
          <InputNumber
            min={1}
            max={record.item.total.ttc}
            value={record.amount}
            suffix='€'
            onChange={(value) => handlePriceChange(record.item, value)}
          />
        ) : (
          <InputNumber
            min={1}
            max={record.item.total.ttc}
            value={record.amount}
            suffix='€'
            disabled
          />
        )
    }
  ]

  return (
    <Space direction='vertical' className='w-100'>
      <Row justify='space-between' className='mb-2'>
        <Col>
          <Title level={4}>{t('panel.cartCredit.title')}</Title>
        </Col>
        <Col>
          <Row>
            <Space>
              <Button
                disabled={
                  totalCredit === 0 || !formState.isValid || useCreateCreditMutation.isPending
                }
                loading={useCreateCreditMutation.isPending}
                onClick={() => useCreateCreditMutation.mutate(selectedItems)}
                type='primary'>
                {t('panel.form.defaultSaveButton')}
              </Button>
              <Button
                icon={<CloseOutlined />}
                onClick={() => Panels.close(PanelsKeys.CART_CREDIT)}
              />
            </Space>
          </Row>
        </Col>
      </Row>

      {!cartLoading && cart && (
        <Col className='w-100'>
          <Row justify='space-between' align='bottom'>
            <FormComponent
              formId='67ac6d3c40a0b1f4212b0d5f'
              innerRef={formikRef}
              disabled={false}
            />
          </Row>
          <Row justify='end'>
            <Statistic
              title={t('panel.cartCredit.total')}
              value={formatCurrency(totalCredit)}
              valueStyle={{color: '#935A55'}}
            />
          </Row>
          <Table dataSource={tableData} columns={columns} pagination={false} scroll={{y: '72vh'}} />
        </Col>
      )}
    </Space>
  )
}

export default withApp(CartCreditPanel)
