import {Typography, Flex, Select, Button} from 'antd'
import React, {Dispatch, SetStateAction, useCallback, useMemo} from 'react'
import {CartItemsWithSuppliers} from '../cartValidation.t'
import {AppContext, withApp} from '@store/app'
import {DefaultOptionType} from 'antd/es/select'
import {CheckCircleFilled} from '@ant-design/icons'
import {Cart, DatadgoErrorScope} from '@shared/interfaces'

const {Text} = Typography

interface CartItemProps extends AppContext {
  cart: Cart
  item: CartItemsWithSuppliers
  cartItems: CartItemsWithSuppliers[]
  setCartItems: Dispatch<SetStateAction<CartItemsWithSuppliers[]>>
  updatedCartItems: string[]
  setUpdatedCartItems: Dispatch<SetStateAction<string[]>>
}

const CartItem: React.FC<CartItemProps> = ({
  cart,
  Datadog,
  cartItems,
  setCartItems,
  updatedCartItems,
  setUpdatedCartItems,
  item,
  t
}: CartItemProps) => {
  const [reported, setReported] = React.useState(false)

  const suppliers = useMemo(() => {
    const result: DefaultOptionType[] = []

    item.supplierArticles?.forEach((supplierArticle) => {
      if (result.find((r) => r.value === supplierArticle.supplier?._id)) return

      result.push({
        value: supplierArticle.supplier?._id,
        label: supplierArticle.supplier?.name
      })
      return {
        value: supplierArticle.supplier?._id,
        label: supplierArticle.supplier?.name
      }
    })

    return result
  }, [item])

  const supplier = useMemo(() => {
    const cartItem = cartItems.find((ci) => ci._id === item._id)
    return typeof cartItem?.supplierArticle !== 'string'
      ? cartItem?.supplierArticle?.supplier?._id
      : null
  }, [cartItems, item._id])

  const onChange = useCallback(
    (value: string) => {
      const cartItem = cartItems.find((ci) => ci._id === item._id)
      const supplierArticle = item.supplierArticles?.find((sa) => sa.supplier?._id === value)

      if (supplierArticle && cartItem) {
        cartItem.supplierArticle = supplierArticle
        setCartItems([...cartItems])

        if (!updatedCartItems.includes(item._id))
          setUpdatedCartItems([...updatedCartItems, item._id])
      }
    },
    [
      cartItems,
      item._id,
      item.supplierArticles,
      setCartItems,
      setUpdatedCartItems,
      updatedCartItems
    ]
  )

  const sendBugReport = useCallback(() => {
    setReported(true)
    Datadog.trackError(new Error(`Cart validation - Missing supplier articles`), {
      scope: DatadgoErrorScope.MARKETPLACE,
      view: 'Cart validation',
      cart: cart,
      cartItem: item
    })
  }, [Datadog, cart, item])

  const showError = useMemo(() => {
    if (supplier && !suppliers.find((s) => s.value === supplier)) {
      const cartItem = cartItems.find((ci) => ci._id === item._id)
      if (cartItem) {
        cartItem.supplierArticle = undefined

        setCartItems([...cartItems])
      }

      return true
    }
    return !suppliers.length
  }, [cartItems, item, setCartItems, supplier, suppliers])

  return (
    <Flex className='w-100 px-4 py-3' align='middle' gap='small' justify='space-between'>
      <Text strong style={{display: 'flex', alignItems: 'center'}}>
        {item.name}
      </Text>
      {!showError && (
        <Select
          value={supplier}
          options={suppliers}
          style={{width: '300px'}}
          placeholder={t('panel.cartValidation.steps.suppliers.selectPlaceholder')}
          onChange={onChange}
        />
      )}
      {!!showError && (
        <Button
          type='default'
          danger
          onClick={sendBugReport}
          disabled={reported}
          icon={reported ? <CheckCircleFilled /> : undefined}>
          {reported
            ? t('panel.cartValidation.steps.suppliers.reported')
            : t('panel.cartValidation.steps.suppliers.missing')}
        </Button>
      )}
    </Flex>
  )
}

export default withApp(CartItem)
