import {Divider, Typography} from 'antd'
import React, {Dispatch, SetStateAction} from 'react'
import CartItem from './cartItem'
import {CartItemsWithSuppliers} from '../cartValidation.t'
import {Cart, CartSubCategory} from '@shared/interfaces'

const {Text} = Typography

interface CartSubCategoryComponentProps {
  cart: Cart
  subCategory: CartSubCategory
  backColor: string
  fontColor: string
  cartItems: CartItemsWithSuppliers[]
  setCartItems: Dispatch<SetStateAction<CartItemsWithSuppliers[]>>
  updatedCartItems: string[]
  setUpdatedCartItems: Dispatch<SetStateAction<string[]>>
}

const CartSubCategoryComponent: React.FC<CartSubCategoryComponentProps> = ({
  cart,
  subCategory,
  backColor,
  fontColor,
  cartItems,
  setCartItems,
  updatedCartItems,
  setUpdatedCartItems
}: CartSubCategoryComponentProps) => {
  return (
    <div style={{overflow: 'hidden'}}>
      <div
        className='py-1 px-4'
        style={{
          background: `${backColor}Af`
        }}>
        <Text strong style={{color: fontColor}}>
          {subCategory.name}
        </Text>
      </div>
      <>
        {subCategory.articles.map((item, index) => (
          <React.Fragment key={item._id}>
            <CartItem
              cart={cart}
              item={item as CartItemsWithSuppliers}
              cartItems={cartItems}
              setCartItems={setCartItems}
              updatedCartItems={updatedCartItems}
              setUpdatedCartItems={setUpdatedCartItems}
            />
            {index < subCategory.articles.length - 1 && <Divider className='mx-3 my-0' />}
          </React.Fragment>
        ))}
      </>
    </div>
  )
}

export default CartSubCategoryComponent
