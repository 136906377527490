import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import {useFormikContext} from 'formik'
import {FormObject} from '@components/forms/form.t'
import {Col, Collapse, Row, Timeline, Typography} from 'antd'
import {useCallback, useMemo} from 'react'
import {RecoveryEvent, RecoveryEventStatus, RecoveryEventType} from '@shared/interfaces'
import {
  faCirclePause,
  faCirclePlay,
  faTimer,
  faCircleCheck,
  faCircleXmark,
  faHourglassEnd
} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'
import {useApp} from '@store/app'
import {faBadgeCheck} from '@fortawesome/pro-solid-svg-icons'

const {Title, Text} = Typography

interface RecoveryTimelineComponentProps {
  label: string
}

const RecoveryTimelineComponent: React.FC<FieldComponentProps<RecoveryTimelineComponentProps>> = ({
  field
}: FieldComponentProps<RecoveryTimelineComponentProps>) => {
  const formProps = useFormikContext<FormObject>()
  const {label} = field

  const {t} = useApp()

  const recoveryEvents = useMemo(() => {
    return formProps.values.recoveryEvents as unknown as RecoveryEvent[]
  }, [formProps.values.recoveryEvents])

  const getEventColor = useCallback((event: RecoveryEvent) => {
    if (event.type === RecoveryEventType.BALANCED) {
      return 'green'
    } else if (event.type === RecoveryEventType.PAUSE) {
      return 'orange'
    } else if (event.type === RecoveryEventType.RESUME) {
      return 'green'
    } else {
      switch (event.status) {
        case RecoveryEventStatus.PENDING:
          return 'gray'
        case RecoveryEventStatus.DONE:
          return 'green'
        case RecoveryEventStatus.ERROR:
          return 'red'
        case RecoveryEventStatus.SUSPENDED:
          return 'orange'
        default:
          return 'gray'
      }
    }
  }, [])

  const getEventDot = useCallback((event: RecoveryEvent) => {
    if (event.type === RecoveryEventType.BALANCED) {
      return <FontAwesomeIcon icon={faBadgeCheck} />
    } else if (event.type === RecoveryEventType.PAUSE) {
      return <FontAwesomeIcon icon={faCirclePause} />
    } else if (event.type === RecoveryEventType.RESUME) {
      return <FontAwesomeIcon icon={faCirclePlay} />
    } else {
      switch (event.status) {
        case RecoveryEventStatus.DONE:
          return <FontAwesomeIcon icon={faCircleCheck} />
        case RecoveryEventStatus.ERROR:
          return <FontAwesomeIcon icon={faCircleXmark} />
        case RecoveryEventStatus.SUSPENDED:
          return <FontAwesomeIcon icon={faHourglassEnd} />
        default:
          return <FontAwesomeIcon icon={faTimer} />
      }
    }
  }, [])

  const getEventDate = useCallback((event: RecoveryEvent) => {
    return moment(event.date).format('DD MMM YYYY')
  }, [])

  const getEventContent = useCallback(
    (event: RecoveryEvent) => {
      return (
        <Col>
          <Row>
            <Text strong>{event.name}</Text>
          </Row>
          <Row>
            <Text type='secondary'>{getEventDate(event)}</Text>
          </Row>
        </Col>
      )
    },
    [getEventDate]
  )

  const timelineItems = useMemo(() => {
    return (
      recoveryEvents?.map((event) => {
        return {
          key: event._id,
          color: getEventColor(event),
          dot: getEventDot(event),
          children: getEventContent(event)
        }
      }) || []
    )
  }, [getEventColor, getEventContent, getEventDot, recoveryEvents])

  const collapseItems = useMemo(() => {
    return [
      {
        key: 'timeline',
        label: <Title level={5}>{label || t('recovery-timeline-widget.title')}</Title>,
        children: (
          <div style={{maxHeight: 450, overflow: 'auto'}} className='px-3 pt-3'>
            <Timeline items={timelineItems} />
          </div>
        )
      }
    ]
  }, [label, t, timelineItems])

  return (
    <Collapse items={collapseItems} defaultActiveKey={['timeline']} style={{flex: 1}}></Collapse>
  )
}

const RecoveryTimelineWidget: FieldComponentFactory = (field) => {
  return {
    generateComponent() {
      return <RecoveryTimelineComponent field={field} />
    }
  }
}

export default RecoveryTimelineWidget
