import {Space} from 'antd'
import React from 'react'
import CartCategory from './cart-category'
import {Cart} from '@shared/interfaces'

interface CartContentProps {
  cart: Cart
}

const CartContent: React.FC<CartContentProps> = ({cart}: CartContentProps) => {
  const categories = cart.categories.internal

  if (!categories || !categories.length) return null

  return (
    <Space direction='vertical' className='w-100'>
      {categories.map((category, index) => {
        return <CartCategory key={index} cart={cart} category={category} />
      })}
    </Space>
  )
}

export default CartContent
