import React, {useState, useMemo, useCallback} from 'react'
import FormComponent from '@components/forms/form'
import {FormObject, FormState} from '@components/forms/form.t'
import {FormikProps} from 'formik'
import {useCallbackRef} from 'use-callback-ref'
import {Button, Col, Row, Space} from 'antd'
import {AppContext, withApp} from '@store/app'
import {CheckCircleFilled} from '@ant-design/icons'
import {useMutation, useQuery} from '@tanstack/react-query'
import {useInmemoriServices} from '@services'
import {PanelsKeys} from '@libs/panels/panels.t'
import {Cart} from '@shared/interfaces'

export interface CartFinancingPlanProps extends AppContext {
  cart: Cart
  onSuccess: () => void
  saveLabel?: string | null
}

const CartFinancingPlan: React.FC<CartFinancingPlanProps> = ({
  cart,
  saveLabel,
  onSuccess,
  t,
  Panels
}) => {
  const [formState, setFormState] = useState<FormState>({
    isDirty: false,
    isValid: false,
    isSubmitting: false
  })

  const formikRef = useCallbackRef<FormikProps<FormObject>>(null, (node) => {
    if (node) {
      const formData = node.values

      const cartTotal = cart.prices.tvas.summary.ttc

      const amount =
        (formData?.payers as FormObject[])?.reduce(
          (partialSum, p) => partialSum - ((p?.amount as number) || 0),
          cartTotal
        ) || cartTotal

      const amountValid = amount >= 0

      if (
        node.dirty !== formState.isDirty ||
        (node.isValid && amountValid) !== formState.isValid ||
        node.isSubmitting !== formState.isSubmitting
      )
        setFormState({
          isDirty: node.dirty,
          isValid: node.isValid && amountValid,
          isSubmitting: node.isSubmitting
        })
    }
  })

  const erpApi = useInmemoriServices().erpApiService

  const financingPlanData = useQuery({
    queryKey: ['financingPlanData', cart._id],
    queryFn: () => erpApi.getCartFinancingPlan(cart._id),
    gcTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false
  })

  const useFinancingPlanMutation = useMutation({
    mutationFn: async (financingPlan: FormObject) =>
      erpApi.upsertCartFinancingPlan(cart._id, financingPlan),
    onSuccess: () => {
      Panels.close(PanelsKeys.CART_FINANCING_PLAN)
      onSuccess()
    }
  })

  const usedForm = useMemo(() => {
    return '6798d6b508a93354bf95815b'
  }, [])

  const onSave = useCallback(() => {
    if (formState.isValid) {
      const formData = formikRef.current?.values

      const cartTotal = cart.prices.tvas.summary.ttc

      const amount = (formData?.payers as FormObject[])?.reduce(
        (partialSum, p) => partialSum - ((p?.amount as number) || 0),
        cartTotal
      )

      const queryData = {
        hasDeposit: formData?.deposit,
        payers: [
          {
            type: 'power',
            profile: (formData?.power as FormObject)?.profile,
            amount: amount,
            comment: (formData?.power as FormObject)?.comment
          },
          ...(formData?.payers as FormObject[])
        ]
      }

      useFinancingPlanMutation.mutate(queryData)
    }
  }, [cart, formState, formikRef, useFinancingPlanMutation])

  const formData = useMemo(() => {
    if (!financingPlanData.isSuccess) return undefined
    const payers = financingPlanData.data?.data?.data

    const power = payers?.find((p) => p.type === 'power')

    return {
      cart: cart as unknown as FormObject,
      power,
      payers: payers?.filter((p) => p.type !== 'power')
    }
  }, [cart, financingPlanData])

  if (!usedForm || !financingPlanData.isSuccess) return null

  return (
    <Col className='w-100 hidden-scroll-container' style={{maxHeight: '670px', overflow: 'scroll'}}>
      <Space direction='vertical' className='w-100'>
        <FormComponent
          formId={usedForm}
          innerRef={formikRef}
          disabled={false}
          data={formData}
          onSubmit={onSave}
        />
        <Row justify='end'>
          <Space direction='horizontal'>
            <Button
              type='default'
              onClick={() => {
                formikRef.current?.submitForm()
              }}
              disabled={!formState.isValid}
              icon={<CheckCircleFilled />}>
              {saveLabel || t('panel.financingPlan.save')}
            </Button>
          </Space>
        </Row>
      </Space>
    </Col>
  )
}

export default withApp(CartFinancingPlan)
