import {Cart, CartItem, House, Option, Product} from '@shared/interfaces'
import React, {createContext, useContext} from 'react'

export interface CatalogArticle extends CartItem {
  belongsToCart?: boolean
}

/**
 * Catalog data
 *
 * @interface CatalogData
 */
export interface CatalogData {
  /** Catalog products (isCatalog = true) to show in the product select filter */
  products: Product[]
  /** Houses to show in the house select filter on the manager view */
  houses: House[]
  /** Articles to show on the catalog */
  articles: CatalogArticle[]
  /** Initial filters values */
  state?: {
    /** default house id */
    house: string
    /** default product id */
    product: string
  }
}

/**
 * Catalog's sidebar filters
 *
 * @interface Filters
 */
export interface Filters {
  /** if true, the catalog would show the extended offer articles */
  extended?: boolean
  /** filter by articles options */
  options?: {option: string; value: string}[]
  /** filter by product */
  product?: string
  /** filter by house (default: user's house) */
  house?: string
  /** search by article's name */
  articleName?: string
}

/**
 * Catalog state interface
 *
 * @interface CatalogState
 */
export interface CatalogState {
  /**
   * @param {CatalogData} data - catalog data
   * Loaded when the catalog component is mounted and updated when filters change
   * Contains all of the catalog data (products, articles, houses and current state)
   * @see erp-api.domains.articles.endpoints.getCatalog
   */
  data?: CatalogData
  /** Sidebar filters state */
  filters?: Filters
  /** Hide sidebar when true, default false */
  sidebarHidden?: boolean
  options?: Option[]
  loading?: boolean
  extended?: boolean
  article?: CatalogArticle
  products?: Product[]
  setData?: React.Dispatch<CatalogData>
  setFilters?: React.Dispatch<Filters>
  setSidebarHidden?: React.Dispatch<boolean>
  setOptions?: React.Dispatch<Option[]>
  setLoading?: React.Dispatch<boolean>
  setExtended?: React.Dispatch<boolean>
  setArticle?: React.Dispatch<CatalogArticle | undefined>
  resetFilters?: (filter: Filters) => void
  unsetFilter?: (key: keyof Filters) => void
  setProducts?: React.Dispatch<Product[]>
  isLoading?: boolean
  hasProject?: boolean
  onClose?: () => void
  cart?: Cart
  optionsSelected?: {[key: string]: string}
  setOptionsSelected?: React.Dispatch<{[key: string]: string}>
  cartItem?: CartItem
}

export const CatalogContext = createContext<CatalogState>({})

export const useCataLogContext = () => useContext(CatalogContext)
