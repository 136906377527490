import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleOutlined,
  QuestionCircleTwoTone,
  ReadOutlined,
  UserOutlined
} from '@ant-design/icons'
import {Row, Button, Typography, Flex, Tag, theme, Tooltip} from 'antd'
import React, {useCallback, useMemo} from 'react'
import CartArticleActions from './cart-article-actions'
import CartArticleCredits from './cart-article-credits'

import {useApp} from '@store/app'
import {PanelsKeys} from '@libs/panels/panels.t'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLocationPin} from '@fortawesome/pro-regular-svg-icons'
import {Cart, CartCategory, CartItem, CartItemStatus, CartStatus} from '@shared/interfaces'

const {useToken} = theme

const {Text} = Typography
interface CartArticleProps {
  cart: Cart
  category: CartCategory
  cartItem: CartItem
}

const CartArticle: React.FC<CartArticleProps> = ({cart, cartItem}: CartArticleProps) => {
  const {Panels, t} = useApp()
  const {token} = useToken()

  const openInCatalog = useCallback(() => {
    Panels.show(PanelsKeys.CATALOG, {
      product: cartItem?.product._id,
      cart,
      cartItem,
      Panels
    })
  }, [cartItem, cart, Panels])

  const hasCatalog = useMemo(() => {
    return (
      cartItem.product.isCatalog &&
      !cart.locked &&
      cart.status === CartStatus.ESTIMATE &&
      !cart.isAbandoned
    )
  }, [cartItem.product.isCatalog, cart.locked, cart.status, cart.isAbandoned])

  const itemData = useMemo(() => {
    if (
      cartItem.status === CartItemStatus.APPROVED ||
      cartItem.status === CartItemStatus.PENDING_SUPPLIER
    )
      return {
        name: cartItem.name,
        price: cartItem.price,
        description: cartItem.internalDescription
      }

    return {
      name: cartItem.product.name,
      description: cartItem.product.description
    }
  }, [cartItem])

  const statusIcon = useMemo(() => {
    if (!cartItem.status) return undefined

    switch (cartItem.status) {
      case CartItemStatus.APPROVED:
        return (
          <Tooltip
            placement='bottomLeft'
            title={t('cart-widget.tooltips.approved')}
            arrow={{pointAtCenter: true}}>
            <CheckCircleTwoTone twoToneColor={token.colorSuccess} className='cursor-help' />
          </Tooltip>
        )

      case CartItemStatus.PENDING:
      case CartItemStatus.PENDING_SALE:
      case CartItemStatus.PENDING_SUPPLIER:
        return (
          <Tooltip
            placement='bottomLeft'
            title={t('cart-widget.tooltips.pending')}
            arrow={{pointAtCenter: true}}>
            <QuestionCircleTwoTone twoToneColor={token.colorWarning} className='cursor-help' />
          </Tooltip>
        )

      case CartItemStatus.MISSING:
        return (
          <Tooltip
            placement='bottomLeft'
            title={t('cart-widget.tooltips.missing')}
            arrow={{pointAtCenter: true}}>
            <CloseCircleTwoTone twoToneColor={token.colorError} className='cursor-help' />
          </Tooltip>
        )
    }
  }, [cartItem.status, t, token.colorError, token.colorSuccess, token.colorWarning])

  const tags = useMemo(() => {
    const tags = []

    if (cartItem.estimate)
      tags.push({
        color: 'warning',
        text: t('cart-widget.tags.estimate'),
        icon: <ExclamationCircleOutlined />
      })

    if (typeof cartItem.supplierArticle !== 'string' && cartItem.supplierArticle?.place)
      tags.push({
        color: 'blue',
        text: t('cart-widget.tags.place', cartItem),
        icon: <FontAwesomeIcon icon={faLocationPin} className='anticon' />
      })

    if (
      typeof cartItem.supplierArticle !== 'string' &&
      cartItem.supplierArticle?.supplier &&
      !cartItem.supplierArticle?.place
    )
      tags.push({
        color: 'purple',
        text: t('cart-widget.tags.supplier', cartItem),
        icon: <UserOutlined />
      })

    if (tags.length) return tags
    return false
  }, [cartItem, t])

  return (
    <Flex vertical>
      <Flex className='w-100 px-4 py-3' align='start' gap='small' justify='space-between'>
        <Flex gap='small'>
          {!cart.locked && <div>{statusIcon}</div>}
          <Flex vertical>
            <Row align='middle'>
              <Text strong>{itemData.name}</Text>
              {hasCatalog && (
                <Button
                  type='text'
                  shape='circle'
                  size='small'
                  icon={<ReadOutlined />}
                  onClick={openInCatalog}
                  className='ms-1'
                />
              )}
            </Row>
            {itemData.description && <Text>{itemData.description}</Text>}
            {tags && (
              <Flex gap='small' className='mt-2'>
                {tags.map((tag, index) => (
                  <Tag key={index} icon={tag.icon} color={tag.color}>
                    {tag.text}
                  </Tag>
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>

        <CartArticleActions cart={cart} cartItem={cartItem} />
      </Flex>
      <CartArticleCredits cart={cart} cartItem={cartItem} />
    </Flex>
  )
}

export default CartArticle
